import styles from "./Cover.module.scss";

export const Cover = ({ heading, subheading, buttonText, onButtonClick }) => {
  return (
    <div className={styles.root}>
      <div className={styles.intro}>
        <div className={styles.introWrapper}>
          <h3
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          <p className={styles.subheading}>{subheading}</p>
          <button className={styles.button} onClick={onButtonClick}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};
