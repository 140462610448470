import { useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSPlugin } from "gsap";
import { ArrowRight } from "../Icons/ArrowRight";
import styles from "./Rolodex.module.scss";

gsap.registerPlugin(ScrollTrigger, CSSPlugin);

export const Rolodex = ({
  title,
  items,
  isClickable,
  isBuildingShifted,
  setIsBuildingShifted,
}) => {
  const rootRef = useRef(null);
  const innerRef = useRef(null);
  const listRef = useRef(null);
  const beforeListRef = useRef(null);
  const afterListRef = useRef(null);
  const detailsRef = useRef(null);
  const [inViewItem, setInViewItem] = useState(items[0]);
  const spacerHeight = 600;

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: rootRef.current,
        start: "top top",
        end: `${(items.length + 1) * spacerHeight}px top`,
        pin: innerRef.current,
        refreshPriority: 1,
        // markers: true,
      });

      gsap.set(rootRef.current, { opacity: 0 });

      gsap.fromTo(
        rootRef.current,
        {
          opacity: 0,
          immediateRender: false,
        },
        {
          opacity: 1,
          immediateRender: false,
          scrollTrigger: {
            trigger: rootRef.current,
            start: "top 75%",
            end: "top 25%",
            scrub: true,
            // markers: true,
          },
        }
      );

      gsap.fromTo(
        rootRef.current,
        {
          opacity: 1,
          immediateRender: false,
        },
        {
          opacity: 0,
          immediateRender: false,
          scrollTrigger: {
            trigger: rootRef.current,
            start: `${(items.length + 1) * spacerHeight}px top`,
            end: `${(items.length + 1) * spacerHeight}px -50%`,
            scrub: true,
            // markers: true,
          },
        }
      );
    }, rootRef);

    return () => ctx.revert();
  }, [items.length]);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      items.forEach((item, i) => {
        let onEnter = () => {
          setInViewItem(item);

          gsap.to(listRef.current, {
            y: `-${100 * i}%`,
            duration: 0.5,
            ease: "power3.inOut",
          });

          gsap.to(afterListRef.current, {
            y: `-${(100 / (items.length - 1)) * i}%`,
            duration: 0.5,
            ease: "power3.inOut",
          });

          let currentItem = beforeListRef.current.children[i];
          if (currentItem) {
            gsap.to(currentItem, {
              y: "0",
              opacity: 1,
              duration: 0.5,
              ease: "power3.inOut",
            });
          }

          let prevItem = beforeListRef.current.children[i - 1];
          if (prevItem) {
            gsap.to(prevItem, {
              y: "-100%",
              opacity: 0,
              duration: 0.5,
              ease: "power3.inOut",
            });
          }

          let prevPrevItem = beforeListRef.current.children[i - 2];
          if (prevPrevItem) {
            gsap.to(prevPrevItem, {
              y: "-200%",
              opacity: 0,
              duration: 0.5,
              ease: "power3.inOut",
            });
          }
        };

        ScrollTrigger.create({
          trigger: rootRef.current,
          start: `${i * spacerHeight} top`,
          end: `${(i + 1) * spacerHeight} top`,
          onEnter: onEnter,
          onEnterBack: onEnter,
          // markers: true,
        });
      });
    }, rootRef);

    return () => ctx.revert();
  }, [items]);

  useLayoutEffect(() => {
    if (isBuildingShifted === null) {
      return;
    }

    const ctx = gsap.context(() => {
      gsap.to(detailsRef.current, {
        autoAlpha: 1,
        ease: "power3.inOut",
        duration: 1,
        runBackwards: !isBuildingShifted,
        immediateRender: true,
      });

      gsap.to(innerRef.current, {
        autoAlpha: 0,
        ease: "power3.inOut",
        duration: 1,
        runBackwards: !isBuildingShifted,
        immediateRender: true,
      });
    }, rootRef);

    return () => ctx.revert();
  }, [isBuildingShifted]);

  const handleOnClick = () => {
    setIsBuildingShifted(true);
  };

  const handleClose = () => {
    setIsBuildingShifted(false);
  };

  return (
    <div className={styles.root} ref={rootRef}>
      <div className={styles.inner} ref={innerRef}>
        <h2 className={styles.heading}>{title}</h2>
        {items && (
          <div className={styles.boxWrapper}>
            <div className={styles.beforeBox} aria-hidden="true">
              <div className={styles.beforeList} ref={beforeListRef}>
                {items.map((item, i) => {
                  if (i < items.length - 1) {
                    return (
                      <div key={i} className={styles.beforeListItem}>
                        <div className={styles.beforeListItemBox}>
                          <h3 className={styles.beforeListTitle}>
                            {item.title}
                          </h3>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.boxInner}>
                <div className={styles.list} ref={listRef}>
                  {items.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className={styles.listItem}
                        style={{ translate: `0 ${100 * i}%` }}
                      >
                        {item.image && (
                          <img
                            src={require(`../../images/rolodex-images/${item.image}`)}
                            alt={item.title}
                            className={styles.listItemImage}
                          />
                        )}
                        <div className={styles.listItemText}>
                          <h3 className={styles.listTitle}>{item.title}</h3>
                          <p className={styles.listText}>{item.text}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {isClickable && (
                <button
                  className={styles.moreButton}
                  onClick={handleOnClick}
                  aria-label="more"
                >
                  <ArrowRight className={styles.moreButtonArrow} />
                </button>
              )}
            </div>
            <div className={styles.afterBox} aria-hidden="true">
              <div className={styles.afterList} ref={afterListRef}>
                {items.map((item, i) => {
                  if (i > 0) {
                    return (
                      <div key={i} className={styles.afterListItem}>
                        {" "}
                        <div className={styles.afterListItemBox}>
                          <h3 className={styles.afterListTitle}>
                            {item.title}
                          </h3>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      {isClickable && (
        <div className={styles.details} ref={detailsRef}>
          <h1>{inViewItem.title}</h1>
          <button onClick={handleClose}>close</button>
        </div>
      )}
    </div>
  );
};
