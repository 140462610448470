export const IconGroup = ({ className }) => (
  <svg
    width="49"
    height="35"
    viewBox="0 0 49 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M32.1014 15.1263C33.1038 10.8811 30.475 6.62703 26.2298 5.62462C21.9846 4.62221 17.7305 7.25101 16.7281 11.4962C15.7257 15.7414 18.3545 19.9955 22.5997 20.9979C26.8449 22.0003 31.099 19.3715 32.1014 15.1263Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.626 34.0003C11.626 26.9429 17.3477 21.2212 24.4051 21.2212C31.4624 21.2212 37.1842 26.9429 37.1842 34.0003H11.626Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5843 4.14119C18.191 2.23669 15.9319 1 13.3926 1C9.16315 1 5.7334 4.42975 5.7334 8.65922C5.7334 12.8887 9.16315 16.3184 13.3926 16.3184"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3916 16.3196C12.2951 16.3196 11.2398 16.4597 10.2257 16.7236C4.91618 18.1251 1 22.9647 1 28.7112H10.135"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.1963 4.14119C30.5896 2.23669 32.8486 1 35.388 1C39.6174 1 43.0472 4.42975 43.0472 8.65922C43.0472 12.8887 39.6174 16.3184 35.388 16.3184"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.3857 16.3196C36.4823 16.3196 37.5376 16.4597 38.5517 16.7236C43.8612 18.1251 47.7774 22.9565 47.7774 28.7112H38.6424"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
