export const IconBulb = ({ className }) => (
  <svg
    className={className}
    width="52"
    height="54"
    viewBox="0 0 52 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8843 27.5637L19.4139 27.0327C19.1212 26.7407 18.6473 26.7407 18.3546 27.0327L18.8843 27.5637ZM22.8709 31.5405L22.3412 32.0715C22.6337 32.3633 23.107 32.3636 23.3999 32.0722L22.8709 31.5405ZM33.6888 21.5253C33.6899 21.1111 33.3549 20.7745 32.9407 20.7734L26.1907 20.7564C25.7765 20.7553 25.4399 21.0903 25.4388 21.5045C25.4378 21.9187 25.7727 22.2553 26.1869 22.2564L32.1869 22.2715L32.1717 28.2715C32.1707 28.6857 32.5056 29.0224 32.9198 29.0234C33.3341 29.0245 33.6707 28.6895 33.6717 28.2753L33.6888 21.5253ZM4.02255 41.3294C3.7293 41.6219 3.72871 42.0968 4.02124 42.39C4.31377 42.6833 4.78864 42.6839 5.0819 42.3913L4.02255 41.3294ZM18.3546 28.0947L22.3412 32.0715L23.4006 31.0095L19.4139 27.0327L18.3546 28.0947ZM23.3999 32.0722L33.4678 22.0551L32.4098 20.9918L22.3419 31.0089L23.3999 32.0722ZM5.0819 42.3913L19.4139 28.0947L18.3546 27.0327L4.02255 41.3294L5.0819 42.3913Z"
      fill="currentColor"
    />
    <path
      d="M26.1621 1.66797V7.99966"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.4372 8.96338L38.9585 13.4338"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.5001 26.3301H44.1685"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 26.0063H7.83169"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.79565 8.73096L13.2661 13.2014"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6201 47.499H33.7061"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.7062 43.6108V47.4478M18.6118 47.4478V43.6108"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6118 46.9746V46.9746C18.6118 50.1597 21.1939 52.7418 24.379 52.7418H27.9391C31.1242 52.7418 33.7063 50.1597 33.7063 46.9746V46.9746"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6202 43.6106C18.6202 43.6106 18.7289 42.4489 18.6202 41.9558C18.5032 41.4292 18.3444 38.0861 16.8651 36.3393C11.2318 30.0625 11.9255 25.8166 11.9255 25.8166C11.9255 17.9518 18.2943 11.5748 26.1591 11.5664C34.0238 11.5664 40.3926 17.9518 40.3926 25.8166C40.3926 25.8166 41.0863 30.0708 35.453 36.3393C33.9737 38.0944 33.8149 41.4292 33.6979 41.9558C33.5892 42.4489 33.6979 43.6106 33.6979 43.6106H18.6119H18.6202Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
