export const IconExclamation = ({ className }) => (
  <svg
    width="44"
    height="38"
    viewBox="0 0 44 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M22.7104 1.72816C22.5169 1.40073 22.0431 1.40074 21.8496 1.72816L1.44595 36.2456C1.24893 36.5789 1.48919 37 1.87638 37H42.6836C43.0708 37 43.3111 36.5789 43.114 36.2456L22.7104 1.72816Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.2803 24.0816V11.9216V24.0816Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.2807 30.9167C23.1202 30.9167 23.8007 30.2362 23.8007 29.3967C23.8007 28.5572 23.1202 27.8767 22.2807 27.8767C21.4413 27.8767 20.7607 28.5572 20.7607 29.3967C20.7607 30.2362 21.4413 30.9167 22.2807 30.9167Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
