import styles from "./Hamburger.module.scss";
import classNames from "classnames";

export const Hamburger = ({ isActive, setIsActive }) => {
  return (
    <button
      className={classNames(styles.root, { [styles.active]: isActive })}
      onClick={() => setIsActive(!isActive)}
      aria-label="menu"
    >
      <div className={styles.line} />
      <div className={styles.line} />
      <div className={styles.line} />
    </button>
  );
};
