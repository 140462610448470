export const IconTransform = ({ className }) => (
  <svg
    className={className}
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.4751 40.0579L40.9296 43.5324"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.4746 43.6435L40.9395 43.6132"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.2936 48.5521C20.4964 52.3297 7.57817 46.4513 2.80073 34.7956C-1.16868 25.1195 1.6493 14.3425 9.04271 7.75708"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6479 2.54538C30.1825 -0.636212 42.5452 5.23205 47.2015 16.5848C51.0093 25.8871 48.565 36.1894 41.8079 42.8253"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8904 5.96934C17.2627 5.96934 18.3751 4.85692 18.3751 3.48467C18.3751 2.11243 17.2627 1 15.8904 1C14.5182 1 13.4058 2.11243 13.4058 3.48467C13.4058 4.85692 14.5182 5.96934 15.8904 5.96934Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M34.7781 50.1278C36.1504 50.1278 37.2628 49.0154 37.2628 47.6431C37.2628 46.2709 36.1504 45.1584 34.7781 45.1584C33.4059 45.1584 32.2935 46.2709 32.2935 47.6431C32.2935 49.0154 33.4059 50.1278 34.7781 50.1278Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M9.9825 10.2316L9.8916 6.71667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.38672 6.24194L9.90162 6.64595"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3456 33.9371C11.8205 31.6544 10.9316 28.9172 10.9316 25.968C10.9316 25.0791 11.0124 24.2105 11.1639 23.3722"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M22.1328 39.9568C19.83 39.4417 17.7392 38.3812 16.002 36.9166"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M39.2527 29.0789C37.8791 35.2703 32.5057 39.9569 25.981 40.2801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M27.0312 11.7467C33.8287 12.585 39.1516 18.1907 39.5556 25.1195"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M12.7495 18.9684C14.81 15.302 18.4259 12.6254 22.7084 11.8578"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M37.4447 25.8163C34.6166 23.2711 27.5363 17.5846 14.002 20.4127"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M23.5869 13.5547C21.4456 16.8979 17.759 25.2912 23.1121 38.0984"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M16.0117 33.9067C18.5267 30.6342 22.0012 24.8064 37.4445 26.5032"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.5858 29.3111C40.813 29.3111 41.8079 28.3162 41.8079 27.089C41.8079 25.8618 40.813 24.8669 39.5858 24.8669C38.3586 24.8669 37.3638 25.8618 37.3638 27.089C37.3638 28.3162 38.3586 29.3111 39.5858 29.3111Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M25.0616 13.8475C26.2832 13.8475 27.2735 12.8572 27.2735 11.6355C27.2735 10.4139 26.2832 9.42358 25.0616 9.42358C23.8399 9.42358 22.8496 10.4139 22.8496 11.6355C22.8496 12.8572 23.8399 13.8475 25.0616 13.8475Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M24.1221 42.5122C25.3438 42.5122 26.3341 41.5219 26.3341 40.3002C26.3341 39.0786 25.3438 38.0883 24.1221 38.0883C22.9005 38.0883 21.9102 39.0786 21.9102 40.3002C21.9102 41.5219 22.9005 42.5122 24.1221 42.5122Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M14.7292 37.7347C15.8728 37.7347 16.7998 36.8077 16.7998 35.6642C16.7998 34.5206 15.8728 33.5936 14.7292 33.5936C13.5857 33.5936 12.6587 34.5206 12.6587 35.6642C12.6587 36.8077 13.5857 37.7347 14.7292 37.7347Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M11.8103 23.3722C13.0263 23.3722 14.0121 22.3864 14.0121 21.1704C14.0121 19.9543 13.0263 18.9685 11.8103 18.9685C10.5942 18.9685 9.6084 19.9543 9.6084 21.1704C9.6084 22.3864 10.5942 23.3722 11.8103 23.3722Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
