import { useState } from "react";
import cx from "classnames";
import { Disclosure } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./SolutionItem.module.scss";

export const SolutionItem = ({
  className,
  index,
  title,
  bodyText,
  toggles,
}) => {
  const [openIndex, setOpenIndex] = useState(0);

  return (
    <div className={cx(className, styles.root)}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {index && <p className={styles.index}>{index}</p>}
          {title && <h3 className={styles.title}>{title}</h3>}
          {bodyText && <p className={styles.bodyText}>{bodyText}</p>}
        </div>
        <div>
          {toggles && (
            <div className={styles.toggles}>
              {toggles.map((item, index) => (
                <div className={styles.toggleWrapper} key={index}>
                  <Disclosure
                    className={styles.toggle}
                    defaultOpen={openIndex === index}
                    onChange={(isOpen) => {
                      if (isOpen) {
                        setOpenIndex(index);
                      }
                    }}
                  >
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={cx(styles.toggleTitle, {
                            [styles.active]: open,
                          })}
                        >
                          {item.title}
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className={styles.toggleArrow}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className={styles.toggleText}>
                          {item.bodyText}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
