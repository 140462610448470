import React, { useEffect, useState } from "react";
import styles from "./Control.module.scss";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ArrowUp } from "../Icons/ArrowUp";
import { Play } from "../Icons/Play.js";
import { Pause } from "../Icons/Pause.js";
import { useScrollLock } from "../../context/ScrollLockContext";
import { useSolutionsModal } from "../../context/SolutionsContext";
import classNames from "classnames";
import { useCookies } from "react-cookie";

gsap.registerPlugin(ScrollToPlugin);

export const Control = ({
  isIntroPaused,
  setIsIntroPaused,
  setIsIntroFinished,
  activeSection,
  setActiveProduct,
}) => {
  const [showBackToTopArrow, setShowBackToTopArrow] = useState(false);
  const { removeScrollLock } = useScrollLock();
  const [cookies] = useCookies(["skipIntro"]);
  const hasWatchedIntroVideoOnce = cookies.skipIntro === "active";
  const { setCurrentSolutionsModal } = useSolutionsModal();

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTopArrow(window.pageYOffset > window.innerHeight * 3.5);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backToTopClickHandler = () => {
    if (activeSection === "lobby") {
      removeScrollLock("lobby");
    }
    setActiveProduct(null);
    setCurrentSolutionsModal(null);
    gsap.to(window, { scrollTo: 0, duration: 1 });
  };

  if (activeSection === "intro") {
    return (
      <div className={styles.root}>
        {hasWatchedIntroVideoOnce && (
          <button
            className={styles.skip}
            onClick={() => setIsIntroFinished(true)}
          >
            Skip intro
          </button>
        )}
        <button
          className={classNames(styles.play, {
            [styles.inverse]: activeSection === "lobby",
          })}
          onClick={() => {
            setIsIntroPaused((currentState) => !currentState);
          }}
          aria-label={isIntroPaused ? "play" : "pause"}
        >
          {isIntroPaused ? <Play /> : <Pause />}
        </button>
      </div>
    );
  } else {
    return showBackToTopArrow ? (
      <div className={styles.root}>
        <button
          className={classNames({
            [styles.inverse]: activeSection === "lobby",
            [styles.backToTop]: true,
          })}
          onClick={() => backToTopClickHandler()}
          aria-label="back to top"
        >
          <ArrowUp />
        </button>
      </div>
    ) : null;
  }
};
