/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import cn from "classnames";
import { IconBox } from "../../IconBox";
import { ProductBox } from "../../ProductBox";
import { Headshot } from "../../Headshot";
import { SolutionItem } from "../../SolutionItem";
import { Icon } from "../../Icon";
import styles from "./SolutionsModal.module.scss";
import { useSolutionsModal } from "../../../context/SolutionsContext";
import { useWindowWidth } from "@react-hook/window-size";

// Tab component
const Tab = ({ className, title, children }) => {
  const { currentSolutionsTab, setCurrentSolutionsTab } = useSolutionsModal();

  // Set the current tab as active when clicked
  const handleClick = () => {
    setCurrentSolutionsTab(title);
  };

  return (
    <div
      className={cn(className, styles.tab, {
        [styles.active]: title === currentSolutionsTab,
      })}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

// TabPanel component
const TabPanel = ({ title, children }) => {
  const { currentSolutionsTab } = useSolutionsModal();

  // Only render the children of the active tab
  return currentSolutionsTab === title ? <div>{children}</div> : null;
};

export const SolutionsModal = ({ className, id, data }) => {
  const { currentSolutionsModal, setCurrentSolutionsModal } =
    useSolutionsModal();
  const windowWidth = useWindowWidth();

  if (!data) return null;

  // Find the object with type "menu"
  const menuObject = data.find((item) => item.type === "menu");

  // Extract the "items" property from the menu object as is, or an empty array if not found
  const menuItems = menuObject ? menuObject.items : [];

  const blocks = data.filter((item) => item.type !== "menu");

  return (
    <div
      className={cn(className, styles.root, {
        [styles.open]: currentSolutionsModal === id,
      })}
    >
      <div className={styles.container}>
        <div
          className={styles.close}
          onClick={() => setCurrentSolutionsModal(null)}
        >
          <Icon icon="close" />
        </div>
        {windowWidth > 1024 && (
          <div className={styles.sidebar}>
            <nav className={styles.menuNav}>
              {menuItems.map((item) => (
                <Tab
                  className={styles.menuItem}
                  title={item.title}
                  key={item.title}
                >
                  <div className={styles.menuIcon}>
                    <Icon icon={item.icon} />
                  </div>
                  <div className={styles.menuText}>{item.text}</div>
                </Tab>
              ))}
            </nav>
            {menuObject.pdfTitle && menuObject.pdfURL && (
              <div className={styles.report}>
                <h4 className={styles.reportTitle}>{menuObject.pdfTitle}</h4>
                <p className={styles.reportText}>
                  {menuObject.downloadDescription}
                </p>
                <a
                  href={menuObject.pdfURL}
                  target={"_blank"}
                  rel="noreferrer"
                  className={styles.reportPdf}
                >
                  {menuObject.downloadText}
                  <Icon icon="download" />
                </a>
              </div>
            )}
          </div>
        )}
        <div className={styles.main}>
          {blocks.map((block, index) => (
            <TabPanel title={block.name} key={index}>
              <div className={styles.mainHead}>
                <p
                  className={styles.eyebrow}
                  dangerouslySetInnerHTML={{ __html: block.eyebrow }}
                />
                <h2 className={styles.heading}>{block.heading}</h2>
              </div>
              <div className={styles.mainContent}>
                <div
                  key={index}
                  className={cn(className, {
                    [styles.headshots]: block.type === "headshots",
                    [styles.solutionItems]: block.type === "toggles",
                    [styles.products]: block.type === "productBoxes",
                    [styles.iconBoxes]: block.type === "iconBoxes",
                    [styles.productsOddGrid]:
                      block?.items?.length % 2 === 1 &&
                      block.type === "productBoxes",
                    [styles.iconBoxesOddGrid]:
                      block?.items?.length % 2 === 1 &&
                      block.type === "iconBoxes",
                  })}
                >
                  {block.items.map((item, index) =>
                    block.type === "headshots" ? (
                      <Headshot
                        key={index}
                        index={item.index}
                        position={item.position}
                        image={item.image}
                        list={item.list}
                      />
                    ) : block.type === "productBoxes" ? (
                      <ProductBox
                        key={index}
                        icon={item.icon}
                        title={item.title}
                        list={item.list}
                      />
                    ) : block.type === "iconBoxes" ? (
                      <IconBox
                        key={index}
                        index={index}
                        title={item.title}
                        icon={item.icon}
                        bodyText={item.text}
                      />
                    ) : block.type === "toggles" ? (
                      <SolutionItem
                        key={index}
                        index={item.index}
                        title={item.title}
                        bodyText={item.bodyText}
                        toggles={item.toggles}
                      />
                    ) : (
                      <></>
                    )
                  )}
                </div>
              </div>
            </TabPanel>
          ))}
        </div>
      </div>
    </div>
  );
};
