export const IconServiceOps = ({ className }) => (
  <svg
    className={className}
    width="42"
    height="41"
    viewBox="0 0 42 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.1225 9.90763C34.1225 9.90763 34.1 9.90763 34.0887 9.90763C34.1056 9.69394 34.1225 9.47462 34.1225 9.25531C34.1225 5.27949 30.8946 2.0516 26.9187 2.0516C25.3779 2.0516 23.9495 2.54084 22.7798 3.36187C21.0984 1.89414 18.8996 1 16.4928 1C11.6228 1 7.60199 4.64966 7.01153 9.36215C3.59806 9.93013 1 12.8881 1 16.459C1 20.4348 4.22789 23.6627 8.20371 23.6627H34.1225C37.9183 23.6627 41 20.5811 41 16.7852C41 12.9893 37.9183 9.90763 34.1225 9.90763Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.6192 7.66553L23.067 13.4143L20.9591 11.3116L13.3811 18.8709"
      stroke="#30294D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.8098 7.66553H28.6193V11.4751"
      stroke="#30294D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.8991 22.2455C35.5871 19.3318 33.7828 16.4121 30.8692 15.7241C27.9555 15.0361 25.0358 16.8403 24.3478 19.754C23.6598 22.6677 25.464 25.5874 28.3777 26.2754C31.2914 26.9634 34.2111 25.1591 34.8991 22.2455Z"
      fill="#30294D"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.8457 35.1993C20.8457 30.3555 24.7728 26.4285 29.6165 26.4285C34.4603 26.4285 38.3874 30.3555 38.3874 35.1993H20.8457Z"
      fill="#30294D"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9582 22.2455C17.6462 19.3318 15.8419 16.4121 12.9283 15.7241C10.0146 15.0361 7.09486 16.8403 6.40686 19.754C5.71887 22.6677 7.52313 25.5874 10.4368 26.2754C13.3505 26.9634 16.2702 25.1591 16.9582 22.2455Z"
      fill="#30294D"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.90479 35.1993C2.90479 30.3555 6.83185 26.4285 11.6756 26.4285C16.5194 26.4285 20.4465 30.3555 20.4465 35.1993H2.90479Z"
      fill="#30294D"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.9286 26.7954C26.6166 23.8817 24.8124 20.962 21.8987 20.274C18.985 19.586 16.0653 21.3903 15.3773 24.3039C14.6893 27.2176 16.4936 30.1373 19.4073 30.8253C22.3209 31.5133 25.2406 29.7091 25.9286 26.7954Z"
      fill="#30294D"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8752 39.7492C11.8752 34.9055 15.8023 30.9784 20.6461 30.9784C25.4898 30.9784 29.4169 34.9055 29.4169 39.7492H11.8752Z"
      fill="#30294D"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
