import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef, useLayoutEffect } from "react";
import styles from "./Text.module.scss";

gsap.registerPlugin(ScrollTrigger);

export const Text = ({ children }) => {
  const container = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.set(container.current, { opacity: 0 });

      gsap.fromTo(
        container.current,
        {
          opacity: 0,
          immediateRender: false,
        },
        {
          opacity: 1,
          immediateRender: false,
          scrollTrigger: {
            trigger: container.current,
            start: "top 80%",
            end: "top 10%",
            scrub: true,
            // markers: true,
          },
        }
      );

      gsap.fromTo(
        container.current,
        {
          opacity: 1,
          immediateRender: false,
        },
        {
          opacity: 0,
          immediateRender: false,
          scrollTrigger: {
            trigger: container.current,
            start: "top top",
            end: "top -40%",
            scrub: true,
            // markers: true,
          },
        }
      );
    }, container);

    return () => ctx.revert();
  }, []);

  return (
    <div className={styles.root} ref={container}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};
