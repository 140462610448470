import React, { useEffect, useState } from "react";
import styles from "./Control.module.scss";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ArrowUp } from "../Icons/ArrowUp";
import { useScrollLock } from "../../context/ScrollLockContext";
import { useSolutionsModal } from "../../context/SolutionsContext";
import classNames from "classnames";

gsap.registerPlugin(ScrollToPlugin);

export const Control = ({ activeSection, setActiveProduct }) => {
  const [showBackToTopArrow, setShowBackToTopArrow] = useState(false);
  const { removeScrollLock } = useScrollLock();
  const { setCurrentSolutionsModal } = useSolutionsModal();

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTopArrow(window.pageYOffset > window.innerHeight * 3.5);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backToTopClickHandler = () => {
    if (activeSection === "lobby") {
      removeScrollLock("lobby");
    }
    setActiveProduct(null);
    setCurrentSolutionsModal(null);
    gsap.to(window, { scrollTo: 0, duration: 1 });
  };

  return showBackToTopArrow ? (
    <div className={styles.root}>
      <button
        className={classNames({
          [styles.inverse]: activeSection === "lobby",
          [styles.backToTop]: true,
        })}
        onClick={() => backToTopClickHandler()}
        aria-label="back to top"
      >
        <ArrowUp />
      </button>
    </div>
  ) : null;
};
