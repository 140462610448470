export const Logo = ({ className }) => (
  <svg
    width="100"
    height="42"
    viewBox="0 0 100 42"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.7245 15.3464C50.0419 15.3464 53.0067 19.7062 53.0067 24.9373C53.0067 30.1684 50.0419 34.4401 44.7245 34.4401C42.8073 34.4401 40.9766 33.4807 40.1917 32.5212V34.0027H35.1353L35.1353 7.49805L40.1917 7.49805V17.2654C40.9748 16.3059 42.8055 15.3464 44.7245 15.3464ZM43.5904 29.9921C46.2939 29.9921 47.9499 27.8126 47.9502 24.8483C47.9499 21.8858 46.2921 19.7062 43.5904 19.7062C42.1089 19.7062 40.7138 20.5775 40.19 21.798L40.19 27.9004C40.8002 29.2072 42.1089 29.9921 43.5904 29.9921ZM76.1974 34.0044H81.2539V22.0591C81.2539 17.962 78.9875 15.3464 75.238 15.3464C72.6224 15.3464 70.8781 16.3923 69.6576 17.7009C68.6118 16.3059 66.9557 15.3464 64.7758 15.3464C62.7705 15.3464 61.0261 16.1313 60.1549 17.2654V15.7838H55.273L55.273 34.0062L60.3295 34.0062L60.3295 22.2336C60.6787 20.664 61.8992 19.8791 63.2061 19.8791C64.9504 19.8791 65.7352 21.186 65.7352 23.1049V34.0027H70.7917V22.2336C71.0527 20.664 72.2731 19.8791 73.5818 19.8791C75.3261 19.8791 76.1974 21.186 76.1974 23.1049V34.0027V34.0044ZM99.9998 21.8862H95.0299C94.7687 20.4911 93.6346 19.6199 91.9791 19.6199C89.6247 19.6199 88.2295 21.798 88.2295 24.8509C88.2295 27.9039 89.6247 29.9956 91.9791 29.9956C93.6346 29.9956 94.6809 29.1244 95.0299 27.7293H99.9998C99.4759 31.6517 96.6 34.2672 91.9791 34.2672C86.8342 34.2672 83.1727 30.3448 83.1727 24.7645C83.2609 19.1842 86.8342 15.3482 91.9791 15.3482C96.6 15.3482 99.4759 18.0502 99.9998 21.8862Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 38.0127C0 40.5419 1.74429 41.8487 3.40038 41.8487C4.18522 41.8487 4.88188 41.6741 5.66672 41.2385L24.3247 29.8169C25.6317 29.1202 26.4165 27.8997 26.4165 26.5911C26.4165 25.3706 25.6317 24.1501 24.3247 23.3653L19.8785 20.7498L24.3247 18.1342C25.5452 17.4375 26.33 16.2153 26.33 14.9084C26.33 13.688 25.6317 12.4675 24.3247 11.6827L5.66672 0.610238C4.9683 0.174605 4.18346 0 3.48681 0C1.74429 0 0 1.30689 0 3.83601L0 9.85194C0 12.2064 1.56968 14.822 3.57499 16.1307L11.8572 21.0126L3.57499 25.8944C1.48149 27.0285 0 29.7304 0 32.085L0 38.0127ZM4.09704 4.53443L4.09704 9.85194C4.09704 10.7232 4.88188 12.1183 5.66672 12.5539L15.8679 18.5699L21.8838 14.9948L4.09704 4.53443ZM15.8679 23.3671L5.66672 29.383C4.88188 29.8186 4.09704 31.2137 4.09704 32.0867L4.09704 37.4042L21.7956 26.942L15.8679 23.3671Z"
      fill="currentColor"
    />
  </svg>
);
