import { useRef, useState, useEffect, useCallback } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Screen } from "../Screen";
import { ProgressBar } from "./ProgressBar";
import { SolutionsModal } from "./SolutionsModal";
import styles from "./Product.module.scss";
import classNames from "classnames";
import { useWindowHeight } from "@react-hook/window-size";
import { ArrowDown } from "../Icons/ArrowDown";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export const Product = ({
  id,
  name,
  bg,
  screens,
  solutions,
  nextName,
  isActive,
  setActiveProduct,
  productIndex,
  setInViewProduct,
  scrollToNext,
  scrollToFirst,
  isLast,
}) => {
  const rootRef = useRef(null);
  const scrollWrapperRef = useRef(null);
  const triggerRef = useRef(null);
  const exitTriggerRef = useRef(null);
  const bgImageRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const windowHeight = useWindowHeight();
  const screensLength = screens.length + 1;

  useEffect(() => {
    rootRef.current.scrollTop = 0;
  }, []);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        scrollWrapperRef.current,
        {
          translateX: 0,
        },
        {
          translateX: `-${(screensLength - 1) * 100}vw`,
          ease: "none",
          scrollTrigger: {
            scroller: rootRef.current,
            trigger: triggerRef.current,
            start: "top bottom",
            end: "bottom bottom",
            scrub: true,
            snap: {
              snapTo: 1 / (screensLength - 1),
              directional: false,
            },
            onUpdate: (self) => {
              setProgress(Math.round(self.progress * (screensLength - 1)));
            },
            onLeaveBack: () => {
              setActiveProduct((currentActiveProduct) => {
                if (currentActiveProduct === productIndex) {
                  return null;
                } else {
                  return currentActiveProduct;
                }
              });
            },
            // markers: true,
          },
        }
      );

      gsap.fromTo(
        scrollWrapperRef.current,
        {
          translateY: 0,
        },
        {
          translateY: "-10vh",
          ease: "none",
          scrollTrigger: {
            scroller: rootRef.current,
            trigger: exitTriggerRef.current,
            start: "top bottom",
            end: "95% bottom",
            scrub: true,
            onLeave: () => {
              isLast ? scrollToFirst() : scrollToNext();
              setActiveProduct((currentActiveProduct) => {
                if (currentActiveProduct === productIndex) {
                  return null;
                } else {
                  return currentActiveProduct;
                }
              });
            },
            // markers: true,
          },
        }
      );
    }, rootRef);

    return () => ctx.revert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screensLength, productIndex, setActiveProduct]);

  useEffect(() => {
    if (isActive) {
      gsap.to(window, {
        duration: 0.7,
        scrollTo: rootRef.current,
        ease: "power2.inOut",
      });
      gsap.to(rootRef.current, {
        duration: 0.7,
        scrollTo: { y: windowHeight },
        ease: "power2.inOut",
      });
    } else {
      gsap.to(rootRef.current, {
        duration: 0.7,
        delay: 1,
        scrollTo: { y: 0 },
        ease: "power2.inOut",
      });
      setIsPlaying(false);
      window.Howler.stop();
    }
  }, [isActive, windowHeight]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: rootRef.current,
        start: "-50% top",
        end: "50% top",
        onEnter: () => setInViewProduct(productIndex),
        onEnterBack: () => setInViewProduct(productIndex),
        // markers: true
      });
    });

    return () => ctx.revert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInViewProduct]);

  const keydownListener = useCallback(
    (e) => {
      e.preventDefault();
      if (e.repeat) {
        return;
      } else if (e.key === "ArrowDown" || e.key === "ArrowRight") {
        goToScreen(progress + 1);
      } else if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
        goToScreen(progress - 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [progress]
  );

  useEffect(() => {
    if (isActive) {
      document.addEventListener("keydown", keydownListener);
    }

    return () => document.removeEventListener("keydown", keydownListener);
  }, [isActive, keydownListener]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        bgImageRef.current,
        {
          y: "-20%",
        },
        {
          y: "20%",
          ease: "none",
          scrollTrigger: {
            trigger: rootRef.current,
            start: "top bottom",
            end: "bottom top",
            scrub: 1,
            // markers: true
          },
        }
      );
    });

    return () => ctx.revert();
  }, []);

  const goToScreen = (screenIndex) => {
    gsap.to(rootRef.current, {
      duration: 0.7,
      scrollTo: { y: windowHeight * screenIndex },
      ease: "power2.inOut",
    });
  };

  return (
    <section
      className={classNames(styles.scrollSectionOuter, {
        [styles.active]: isActive,
      })}
      ref={rootRef}
    >
      <div className={styles.scrollSectionInner}>
        <div className={styles.scrollWrapper} ref={scrollWrapperRef}>
          {screens?.map((screen, index) => (
            <div className={styles.scrollSection} key={index}>
              <Screen
                productName={name}
                screen={screen}
                onButtonClick={() => setActiveProduct(productIndex)}
                isPlaying={isPlaying && isActive}
                setIsPlaying={setIsPlaying}
              />
            </div>
          ))}
          <div className={styles.scrollSection} key={"continue"}>
            <div className={styles.continue}>
              <div>{isLast ? "Back to " : "Continue to"}</div>
              <div dangerouslySetInnerHTML={{ __html: nextName }} />
              <div
                className={styles.nextIcon}
                onClick={() => {
                  isLast ? scrollToFirst() : scrollToNext();
                  setActiveProduct(null);
                }}
              >
                <ArrowDown />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.bg}>
            <div className={styles.bgInner}>
              <img
                src={require(`../../images/backgrounds/${bg}`)}
                alt={name}
                ref={bgImageRef}
              />
            </div>
          </div>
          <ProgressBar
            numSteps={screensLength - 1}
            progress={progress}
            className={styles.progressBar}
            goToScreen={goToScreen}
            onPrev={() => {
              goToScreen(progress - 1);
            }}
            onNext={() => {
              goToScreen(progress + 1);
            }}
          />
        </div>
      </div>
      <div
        ref={triggerRef}
        style={{ height: `${(screensLength - 1) * 100}vh` }}
      />
      <div ref={exitTriggerRef} style={{ height: "20vh" }} />
      <SolutionsModal id={id} data={solutions} />
    </section>
  );
};
