export const IconCharts = ({ className }) => (
  <svg
    className={className}
    width="46"
    height="42"
    viewBox="0 0 46 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.7305 1.33203H40.5971V7.1987"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.39868 21.8654L18.5987 10.132L27.3987 14.532L40.5987 1.33203M37.6654 40.597V14.2244H43.532V40.597"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.9316 40.5761V26.957H31.7983V40.5761"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1982 40.6011V22.582H20.0649V40.6011"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.4668 40.5852V31.7852H8.33347V40.5852"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45 40.5977H1"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
