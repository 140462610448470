import { useRef, useLayoutEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useSize from "@react-hook/size";
import { Title } from "./Title/Title";
import { Rolodex } from "../Rolodex/Rolodex";
import { Section } from "../Section/Section";
import { Clouds } from "./Clouds/Clouds";
import { Text } from "./Text/Text";
import buildingImage from "../../images/building.jpg";
import buildingImageMobile from "../../images/building-mobile.jpg";
import styles from "./Skyscraper.module.scss";
import { Intro } from "../Intro/Intro";
import fogImage from "../../images/fog.png";
import { useScrollLock } from "../../context/ScrollLockContext";
import { useWindowWidth } from "@react-hook/window-size";

gsap.registerPlugin(ScrollTrigger);

export const Skyscraper = ({
  title,
  opportunities,
  capabilities,
  ops,
  activeSection,
  setActiveSection,
  isIntroPaused,
  isIntroFinished,
  setIsIntroFinished,
}) => {
  const windowWidth = useWindowWidth();

  const container = useRef();
  const building = useRef();
  const buildingContainer = useRef();
  const firstSectionRef = useRef();
  const fogContainerRef = useRef();
  const fogRef = useRef();
  const [isBuildingShifted, setIsBuildingShifted] = useState(null);
  const { addScrollLock, removeScrollLock } = useScrollLock();

  const containerHeight = useSize(buildingContainer)[1];
  const buildingHeight = useSize(building)[1];

  const buildingTranslate = () => {
    gsap.fromTo(
      building.current,
      {
        y: "100vh",
      },
      {
        y: 0,
        duration: 1,
        delay: 1,
        ease: "power3.out",
      }
    );

    gsap.fromTo(
      firstSectionRef.current,
      {
        y: "100vh",
      },
      {
        y: 0,
        duration: 1,
        delay: 1,
        ease: "power3.out",
      }
    );
  };

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: container.current,
        start: "top top",
        end: "bottom bottom",
        pin: buildingContainer.current,
        pinSpacing: false,
        onEnterBack: () => setActiveSection("skyscraper"),
        // markers: true,
      });

      if (windowWidth <= 1024) {
        gsap.fromTo(
          building.current,
          {
            x: 0,
          },
          {
            x: "44%",
            scrollTrigger: {
              trigger: firstSectionRef.current,
              start: "top top",
              end: "75% top",
              scrub: true,
              // markers: true,
            }
          }
        )
      }

      gsap.fromTo(
        fogRef.current,
        {
          y: "-50%",
        },
        {
          y: "-90%",
          scrollTrigger: {
            trigger: fogContainerRef.current,
            start: "top 25%",
            end: "top top",
            scrub: 1,
          },
          ease: "none",
        }
      );

      gsap.to(building.current, {
        y: containerHeight - buildingHeight,
        scrollTrigger: {
          trigger: container.current,
          start: "top top",
          end: "bottom bottom",
          scrub: true,
          pinnedContainer: buildingContainer,
        },
        ease: "none",
      });
    }, container);

    return () => ctx.revert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerHeight, buildingHeight, windowWidth]);

  useLayoutEffect(() => {
    if (isBuildingShifted === null) {
      return;
    }

    if (isBuildingShifted) {
      addScrollLock("skyscraper");
    } else {
      removeScrollLock("skyscraper");
    }

    const ctx = gsap.context(() => {
      gsap.to(buildingContainer.current, {
        x: "-60%",
        ease: "power3.inOut",
        duration: 1,
        runBackwards: !isBuildingShifted,
        immediateRender: true,
      });
    }, container);

    return () => {
      removeScrollLock("skyscraper");
      ctx.revert();
    };
  }, [isBuildingShifted, addScrollLock, removeScrollLock]);

  return (
    <>
      <Intro
        isActive={activeSection === "intro"}
        isPaused={isIntroPaused}
        isFinished={isIntroFinished}
        setIsFinished={setIsIntroFinished}
        setActiveSection={setActiveSection}
        buildingTranslate={buildingTranslate}
      />
      <div className={styles.root} id="skyscraper" ref={container}>
        <div className={styles.buildingContainer} ref={buildingContainer}>
          <img
            src={windowWidth > 1024 ? buildingImage : buildingImageMobile}
            className={styles.building}
            ref={building}
            alt=""
          />
        </div>
        <div ref={firstSectionRef}>
          <Section>
            {windowWidth > 1024 && <Clouds />}
            <Title {...title} />
          </Section>
        </div>
        <Section>
          <Rolodex {...opportunities} />
          {windowWidth > 1024 && (
            <Clouds className={styles.opportunitiesClouds} cloudId={1} />
          )}
        </Section>
        <Section>
          <Rolodex
            {...capabilities}
            // isClickable={true}
            isBuildingShifted={isBuildingShifted}
            setIsBuildingShifted={setIsBuildingShifted}
          />
          {windowWidth > 1024 && (
            <Clouds className={styles.capabilitiesClouds} cloudId={2} />
          )}
        </Section>
        <Section>
          <Text>
            <h2 className={styles.h2}>
              The Autonomous Digital Enterprise is how you operationalize
              innovation to seize opportunity.
            </h2>
          </Text>
        </Section>
        <Section>
          <Text>
            <h2 className={styles.h2}>{ops.title}</h2>
            <p className={styles.text}>{ops.text}</p>
          </Text>
        </Section>
      </div>
      <div className={styles.fogContainer} ref={fogContainerRef}>
        <img src={fogImage} className={styles.fog} ref={fogRef} alt="" />
      </div>
    </>
  );
};
