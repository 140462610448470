import { Header } from "../../components/HeaderInternal/Header";
import { Skyscraper } from "../../components/SkyscraperInternal/Skyscraper";
import { Lobby } from "../../components/LobbyInternal/Lobby";
import { Control } from "../../components/ControlInternal/Control";
import { data } from "../../data-internal";
import { SolutionsProvider } from "../../context/SolutionsContext";
import { useState, useRef, useEffect } from "react";
import { ScrollLockProvider } from "../../context/ScrollLockContext";
import { Analytics } from "@vercel/analytics/react";
import { CookiesProvider } from "react-cookie";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styles from "./Internal.module.scss";

function InternalPage() {
  const [activeSection, setActiveSection] = useState("intro");
  const [activeProduct, setActiveProduct] = useState(null);
  const [isIntroPaused, setIsIntroPaused] = useState(false);
  const [isIntroFinished, setIsIntroFinished] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(-1);
  const containerRef = useRef();

  useEffect(() => {
    const ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: containerRef.current,
        start: "top top",
        end: "bottom bottom",
        onUpdate: (self) => setScrollDirection(self.direction),
        // markers: true,
      });
    }, containerRef);

    return () => ctx.revert();
  }, []);

  return (
    <>
      <CookiesProvider>
        <ScrollLockProvider>
          <SolutionsProvider>
            <div className={styles.root} ref={containerRef}>
              <Header
                setActiveSection={setActiveSection}
                activeSection={activeSection}
                scrollDirection={scrollDirection}
              />
              <Skyscraper
                {...data.skyscraper}
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                isIntroPaused={isIntroPaused}
                isIntroFinished={isIntroFinished}
                setIsIntroFinished={setIsIntroFinished}
              />
              <Lobby
                {...data.lobby}
                isActive={activeSection === "lobby"}
                setActiveSection={setActiveSection}
                activeProduct={activeProduct}
                setActiveProduct={setActiveProduct}
              />
              <Control
                isIntroPaused={isIntroPaused}
                setIsIntroPaused={setIsIntroPaused}
                setIsIntroFinished={setIsIntroFinished}
                activeSection={activeSection}
                setActiveProduct={setActiveProduct}
              />
            </div>
          </SolutionsProvider>
        </ScrollLockProvider>
        <Analytics />
      </CookiesProvider>
    </>
  );
}

export default InternalPage;
