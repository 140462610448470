export const IconPlatform = ({ className }) => (
  <svg
    className={className}
    width="46"
    height="47"
    viewBox="0 0 46 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 45.7242C10.846 45.6893 1 35.8155 1 23.6545C1 11.4934 10.846 1.61958 23 1.58472C35.154 1.61958 45 11.4865 45 23.6545C45 35.8224 35.154 45.6893 23 45.7242Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.1679 27.3918C33.9755 34.9367 29.3315 42.2863 22.9999 45.6892C18.9695 43.5205 15.6224 39.7621 13.4189 35.369"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9852 28.2704C10.6854 26.7363 10.532 25.1883 10.532 23.6543C10.532 14.91 15.6223 5.58706 22.9998 1.61938C29.3313 5.02224 33.9684 12.3161 35.1608 19.8609"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.2332 23.6541H44.9999"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 23.6541H31.6954"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 15.2695L23 45.6505"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 1.65039L23 6.88849"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.2333 23.6336C39.2333 25.7116 37.5458 27.3991 35.4678 27.3991C33.3899 27.3991 31.7024 25.7116 31.7024 23.6336C31.7024 21.5556 33.3899 19.8682 35.4678 19.8682C37.5458 19.8682 39.2333 21.5556 39.2333 23.6336Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6435 31.9312C15.6435 34.0092 13.956 35.6967 11.878 35.6967C9.80003 35.6967 8.11255 34.0092 8.11255 31.9312C8.11255 29.8533 9.80003 28.1658 11.878 28.1658C13.956 28.1658 15.6435 29.8533 15.6435 31.9312Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.7653 11.1951C26.7653 13.2731 25.0778 14.9606 22.9998 14.9606C20.9219 14.9606 19.2344 13.2731 19.2344 11.1951C19.2344 9.11717 20.9219 7.42969 22.9998 7.42969C25.0778 7.42969 26.7653 9.11717 26.7653 11.1951Z"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
