export const IconVisibility = ({ className }) => (
  <svg
    className={className}
    width="48"
    height="40"
    viewBox="0 0 48 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2441 33.5449C20.8028 33.9111 22.3989 34.0943 24 34.0906C37.4102 34.0906 47 20.4466 47 20.4466C44.8736 17.6116 42.3833 15.0689 39.5932 12.8838M24 29.4127C26.4813 29.4127 28.8611 28.427 30.6156 26.6724C32.3702 24.9178 33.3559 22.5381 33.3559 20.0567M30.6271 13.4296C28.8695 11.672 26.4857 10.6846 24 10.6846C21.5143 10.6846 19.1305 11.672 17.3729 13.4296C15.6153 15.1872 14.6278 17.5711 14.6278 20.0567C14.6278 22.5424 15.6153 24.9262 17.3729 26.6838M34.5254 9.5313C31.29 7.78199 27.6778 6.84549 24 6.80249C10.5898 6.80249 1 20.4466 1 20.4466C4.2399 24.7604 8.32538 28.3684 13.0068 31.0499L34.5254 9.5313Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.7089 1.33203L5.28516 38.7558"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
