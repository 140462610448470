export const IconBriefcase = ({ className }) => (
  <svg
    className={className}
    width="46"
    height="39"
    viewBox="0 0 46 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45 9.06299H1V37.389H45V9.06299Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.4359 17.1265H17.5718V22.2301H28.4359V17.1265Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.4282 19.6782H44.9999"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 19.6782H17.5717"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9368 9.06314V1H31.0631V9.06314"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
