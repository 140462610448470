export const IconSupport = ({ className }) => (
  <svg
    className={className}
    width="48"
    height="50"
    viewBox="0 0 48 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.57129 20.5803V17.2945C7.57129 8.25882 14.1427 0.865967 23.1784 0.865967H24.8213C33.857 0.865967 40.4284 8.25882 40.4284 17.2945V20.5803M30.5713 45.7751C30.5713 44.9036 30.2251 44.0679 29.6089 43.4517C28.9927 42.8355 28.157 42.4893 27.2856 42.4893H23.9999C23.1284 42.4893 22.2927 42.8355 21.6765 43.4517C21.0603 44.0679 20.7141 44.9036 20.7141 45.7751C20.7141 46.6465 21.0603 47.4822 21.6765 48.0984C22.2927 48.7146 23.1284 49.0608 23.9999 49.0608H27.2856C28.157 49.0608 28.9927 48.7146 29.6089 48.0984C30.2251 47.4822 30.5713 46.6465 30.5713 45.7751ZM30.5713 45.7751C30.5713 45.7751 40.4284 45.7751 40.4284 34.2751"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8571 34.2749H3.95714C3.17286 34.2749 2.4207 33.9633 1.86613 33.4088C1.31156 32.8542 1 32.102 1 31.3177V23.5372C1 22.7529 1.31156 22.0008 1.86613 21.4462C2.4207 20.8916 3.17286 20.5801 3.95714 20.5801H10.8571V34.2749ZM37.1429 34.2749H44.0429C44.8271 34.2749 45.5793 33.9633 46.1339 33.4088C46.6884 32.8542 47 32.102 47 31.3177V23.5372C47 22.7529 46.6884 22.0008 46.1339 21.4462C45.5793 20.8916 44.8271 20.5801 44.0429 20.5801H37.1429V34.2749Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
