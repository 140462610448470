export const IconClose = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L17 17" stroke="currentColor" strokeWidth="2" />
    <path d="M17 1L0.999999 17" stroke="currentColor" strokeWidth="2" />
  </svg>
);
