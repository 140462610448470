import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const ScrollLockContext = createContext({
  addScrollLock: () => null,
  removeScrollLock: () => null,
});

const ScrollLockProvider = ({ children }) => {
  const [scrollLocks, setScrollLocks] = useState([]);

  const addScrollLock = useCallback(
    (id) => {
      setScrollLocks((current) => {
        // returns array with id added, but without duplicates
        return [...new Set([...current, id])];
      });
    },
    [setScrollLocks]
  );

  const removeScrollLock = useCallback(
    (id) => {
      setScrollLocks((current) => {
        return current.filter((item) => item !== id);
      });
    },
    [setScrollLocks]
  );

  useEffect(() => {
    console.log("current scroll locks: " + scrollLocks.toString(", "));
    if (scrollLocks.length > 0) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    }
  }, [scrollLocks]);

  return (
    <ScrollLockContext.Provider
      value={useMemo(
        () => ({
          addScrollLock: addScrollLock,
          removeScrollLock: removeScrollLock,
        }),
        [addScrollLock, removeScrollLock]
      )}
    >
      {children}
    </ScrollLockContext.Provider>
  );
};

const useScrollLock = () => useContext(ScrollLockContext);

export { ScrollLockProvider, useScrollLock };
