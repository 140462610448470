export const ArrowPopup = ({ className }) => (
  <svg
    className={className}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 25.9795H27V1.97949L2 1.97949V9"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M1 14.9795H13.0208M13.0208 14.9795V27.0003M13.0208 14.9795L1 27.0003"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);
