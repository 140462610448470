import React from "react";
import cx from "classnames";
import { IconList } from "./IconList";
import styles from "./Icon.module.scss";

export const Icon = ({ className, icon }) => {
   // Check if icon exists
   if (icon !== null) {
    // Get the icon to use it in Icon List Component
    const DynamicIcon = IconList[icon];
    if (DynamicIcon !== undefined) {
      return <DynamicIcon className={cx(className, styles.root)} />;
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
};
