export const IconTruck = ({ className }) => (
  <svg
    className={className}
    width="50"
    height="33"
    viewBox="0 0 50 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6678 1.05396H34.7784C36.8228 1.05396 36.645 2.47614 36.2894 3.80944C35.9339 5.14274 33.0006 17.0536 33.0006 17.0536H41.9782L45.5337 15.2758L49.0002 17.0536V24.1645C49.0002 25.32 48.5558 25.9422 47.2225 25.9422H40.1116M4.55688 25.9422H10.4234M18.7788 25.9422H31.7562"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.3103 8.16748H40.1101L45.5322 15.2784M24.1105 8.16748H4.55547M20.5551 15.2784H1"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5999 26.835C18.2533 28.0545 17.5373 29.1367 16.5504 29.9326C15.5635 30.7285 14.3542 31.199 13.0889 31.2793C10.6889 31.2793 9.35563 29.3238 10.0667 26.835C10.4133 25.6154 11.1293 24.5332 12.1162 23.7373C13.1031 22.9414 14.3124 22.471 15.5777 22.3906C17.8888 22.3906 19.3109 24.3461 18.5999 26.835ZM39.9327 26.835C39.5861 28.0545 38.8701 29.1367 37.8832 29.9326C36.8963 30.7285 35.687 31.199 34.4217 31.2793C32.0217 31.2793 30.6884 29.3238 31.3995 26.835C31.7461 25.6154 32.4621 24.5332 33.449 23.7373C34.4359 22.9414 35.6452 22.471 36.9105 22.3906C39.2216 22.3906 40.6438 24.3461 39.9327 26.835Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
