import React from "react";
import cx from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styles from "./Section.module.scss";

gsap.registerPlugin(ScrollTrigger);

export const Section = ({ children, className }) => {

  return (
    <div className={cx(className, styles.root)}>
      {children}
    </div>
  );
};
