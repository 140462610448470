export const Pause = ({ className }) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    className={className}
  >
    <path
      d="M1 1H5V17H1V1Z"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5557 1H14.5557V17H10.5557V1Z"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
