import styles from "./Lobby.module.scss";
import { Product } from "../Product/Product";
import { ProductMobile } from "../Product/ProductMobile";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Footer } from "../Footer/Footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useSolutionsModal } from "../../context/SolutionsContext";
import { OpsIntro } from "../OpsIntro/OpsIntro";
import { useScrollLock } from "../../context/ScrollLockContext";
import { useWindowWidth } from "@react-hook/window-size";

gsap.registerPlugin(ScrollToPlugin);

export const Lobby = ({ intro, ops, isActive, setActiveSection, activeProduct, setActiveProduct }) => {
  const [inViewProduct, setInViewProduct] = useState(null);
  const { setCurrentSolutionsTab } = useSolutionsModal();
  const productRefs = useRef([]);
  const { addScrollLock, removeScrollLock } = useScrollLock();
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (activeProduct === null) {
      removeScrollLock("lobby");
      setCurrentSolutionsTab(null);
    } else {
      addScrollLock("lobby");
    }

    return () => removeScrollLock("lobby");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProduct]);

  useEffect(() => {
    if (isActive) {
      ScrollTrigger.refresh();
    }
  }, [isActive]);

  return (
    <div
      className={classNames(styles.root, { [styles.active]: isActive })}
      id="lobby"
    >
      <div className={styles.intro}>
        <div
          className={classNames(styles.doorLeft, {
            [styles.open]: isActive,
          })}
        >
          <div className={styles.doorImageLeft} />
        </div>
        <div
          className={classNames(styles.doorRight, {
            [styles.open]: isActive,
          })}
        >
          <div className={styles.doorImageRight} />
        </div>
        <div
          className={classNames(styles.introInner, {
            [styles.open]: isActive,
          })}
        >
          <h2 className={styles.title}>{intro.title}</h2>
          <p className={styles.subhead}>{intro.subhead}</p>
          <button
            className={styles.button}
            onClick={() => setActiveSection("lobby")}
          >
            {intro.buttonText}
          </button>
        </div>
      </div>
      <div className={styles.opsWrapper}>
        <OpsIntro
          isActive={isActive}
          productRefs={productRefs}
          setInViewProduct={setInViewProduct}
        />
        {windowWidth > 1024 &&
          ops.map((opsItem, index) => (
            <div
              id={"ops-" + (index + 1)}
              key={index}
              ref={(el) => (productRefs.current[index] = el)}
            >
              <Product
                {...opsItem}
                nextName={ops[(index + 1) % ops.length].name}
                index={index}
                isActive={index === activeProduct}
                setActiveProduct={setActiveProduct}
                setInViewProduct={setInViewProduct}
                productIndex={index}
                isLast={index === ops.length - 1}
                scrollToNext={() => {
                  gsap.to(window, {
                    duration: 0.7,
                    scrollTo: productRefs.current[index + 1],
                    ease: "power2.inOut",
                  });
                }}
                scrollToFirst={() => {
                  gsap.to(window, {
                    duration: 0.7,
                    scrollTo: productRefs.current[0],
                    ease: "power2.inOut",
                  });
                }}
              />
            </div>
          ))}
        {windowWidth <= 1024 &&
          ops.map((opsItem, index) => (
            <div
              id={"ops-" + (index + 1)}
              key={index}
              ref={(el) => (productRefs.current[index] = el)}
            >
              <ProductMobile
                {...opsItem}
                nextName={ops[(index + 1) % ops.length].name}
                index={index}
                isActive={index === activeProduct}
                setActiveProduct={setActiveProduct}
                setInViewProduct={setInViewProduct}
                productIndex={index}
                isLast={index === ops.length - 1}
                scrollToNext={() => {
                  gsap.to(window, {
                    duration: 0.7,
                    scrollTo: productRefs.current[index + 1],
                    ease: "power2.inOut",
                  });
                }}
                scrollToFirst={() => {
                  gsap.to(window, {
                    duration: 0.7,
                    scrollTo: productRefs.current[0],
                    ease: "power2.inOut",
                  });
                }}
              />
            </div>
          ))}

        <nav className={styles.productNavContainer}>
          <div className={styles.productNavInner}>
            <ul
              className={classNames(styles.productNav, {
                [styles.active]: isActive,
                [styles.open]: inViewProduct === null,
              })}
            >
              {ops.map((opsItem, index) => (
                <li key={index}>
                  {windowWidth > 1024 ? (
                    <button
                      className={classNames(styles.productNavItem, {
                        [styles.active]: inViewProduct === index,
                      })}
                      onClick={() => {
                        setActiveProduct(null);
                        gsap.to(window, {
                          duration: 0.7,
                          scrollTo: productRefs.current[index],
                          ease: "power2.inOut",
                        });
                      }}
                    >
                      <span
                        className={styles.productNavItemName}
                        dangerouslySetInnerHTML={{ __html: opsItem.name }}
                      ></span>
                    </button>
                  ) : (
                    <div
                      className={classNames(styles.productNavItem, {
                        [styles.active]: inViewProduct === index,
                      })}
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
      <Footer />
    </div>
  );
};
