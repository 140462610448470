export const data = {
  skyscraper: {
    title: {
      smallHeading: "Introducing the",
      largeHeading: "Autonomous Digital Enterprise",
    },
    opportunities: {
      title: "Where business makes the most of every opportunity:",
      items: [
        {
          title: "Making infrastructure your greatest advantage.",
          text: "You already have a built-in differentiator and advantage. You need only to optimize the value it can deliver through automation.",
        },
        {
          title: "Transforming systems of record into systems of action.",
          text: "Creating a bridge between back-end systems and frontline experiences, enabling automated workflows that give you the real-time insights to take action.",
        },
        {
          title: "Ensuring data unlocks continuous growth.",
          text: "No longer IT “plumbing,” your data and infrastructure become a foundation for continuous innovation that’s centered on the customer.",
        },
      ],
    },
    capabilities: {
      title:
        "Enabling the Autonomous Digital Enterprise requires these capabilities.",
      items: [
        {
          title: "Artificial Intelligence",
          text: "To make the most of both your existing and future technologies, freeing up your people to do more valuable work and automating at the scale business requires.",
          image: "ai.jpg",
        },
        {
          title: "Observability",
          text: "Having visibility across the business, on-prem, in the cloud, and out to the edge.",
          image: "observability.jpg",
        },
        {
          title: "Orchestration",
          text: "To do more than automate parts of your business, but optimize how they connect, orchestrate, and optimize data to yield actionable insights.",
          image: "orchestration.jpg",
        },
        {
          title: "Resilience",
          text: "Cybersecurity and data recovery that work together to mitigate risk.",
          image: "resilience.jpg",
        },
      ],
    },
    ops: {
      title: "In short, Ops are everything.",
      text: "BMC makes the most of your new and existing technology. We connect and optimize operations across your enterprise. So you can innovate, adapt, and grow faster.",
    },
  },
  lobby: {
    intro: {
      title: (
        <>
          We call it{" "}
          <span className="ops-title">
            <span>ConnectedDigital</span>Ops
          </span>
        </>
      ),
      subhead:
        "In the world of hybrid IT, it’s not what you do, but how you operationalize it.",
      buttonText: "Get started",
    },
    ops: [
      {
        id: "connected-ops",
        bg: "connected-ops.jpg",
        name: "<span class='ops-title'><span>ConnectedDigital</span>Ops</span>",
        screens: [
          {
            type: "cover",
            data: {
              heading:
                "<span class='ops-title'><span>Connected&ZeroWidthSpace;Digital</span>Ops</span>",
              subheading:
                "BMC makes the most of your technology by optimizing and connecting otherwise siloed operations to fuel innovation across your enterprise.",
              buttonText: "Explore",
            },
          },
          {
            type: "introduction",
            data: {
              heading:
                "<span class='ops-title'><span>ConnectedDigital</span>Ops</span>. It’s how you operationalize innovation.",
              text: [
                "Every enterprise is in the innovation business. Which means there’s no waiting for the next big idea. You have to operationalize innovation by connecting and optimizing all your Ops—new and old—across your entire technology landscape.",
                "Bridge back-end systems to frontline experiences. And ensure that everyone has the real-time insights to see opportunity, react in real-time, and deliver a new and better experience at scale.",
                "That’s the promise of the Autonomous Digital Enterprise.",
              ],
              audioImage: "headshot-connected-ops.png",
              audioHeading: "Hear from an expert",
              audioText:
                "Ram Chakravarti, Chief Technology Officer, describes Connected Digital Ops.",
              audioFile: "audio/connected-ops.mp3",
            },
          },
          {
            type: "solution",
            data: {
              id: "connected-ops",
              name: "<span class='ops-title'><span>ConnectedDigital</span>Ops</span>",
              heading:
                "<span class='ops-title'>The <span>ConnectedDigital</span>Ops <span>Solution</span></span>",
              bodyText:
                "Build an Autonomous Digital Enterprise with the most comprehensive portfolio of infrastructure software, partners, and expertise to connect and optimize hybrid IT.",
              items: [
                {
                  name: "properties",
                  icon: "group",
                  heading: "Portfolio Properties",
                  bodyText:
                    "The value BMC delivers through our product portfolio and how we can help bring the vision for Connected Digital Ops to life.",
                },
                {
                  name: "solutions",
                  icon: "target",
                  heading: "Solutions",
                  bodyText:
                    "The different solution areas and products we offer that deliver on our promise.",
                },
              ],
            },
          },
        ],
        solutions: [
          {
            type: "menu",
            items: [
              {
                title: "properties",
                icon: "group",
                text: "Properties",
              },
              {
                title: "solutions",
                icon: "target",
                text: "Solutions",
              },
            ],
          },
          {
            type: "iconBoxes",
            name: "properties",
            eyebrow:
              "The <span class='ops-title'><span>ConnectedDigital</span>Ops</span> Solution",
            heading: "Portfolio properties",
            items: [
              {
                icon: "transform",
                title: "Connect across your entire technology landscape.",
                text: "Infinitely adapt to any environment, from on-prem to cloud to edge—so you can seamlessly automate and transform your IT infrastructure, wherever it lives.",
              },
              {
                icon: "workflows",
                title: "Turn systems of record into systems of action.",
                text: "Create a bridge between back-end systems and frontline experiences, enabling automated workflows that give your enterprise the real-time insights they need to take action.",
              },
              {
                icon: "atom",
                title: "Make “Ops” the catalyst for competitive advantage.",
                text: "Elevate Ops to be the most mission critical part of your business, a foundation for continuous innovation that’s connected to the customer, and essential to driving outcomes.",
              },
            ],
          },
          {
            type: "productBoxes",
            name: "solutions",
            eyebrow:
              "The <span class='ops-title'><span>ConnectedDigital</span>Ops</span> Solution",
            heading: "Solutions + Products",
            items: [
              {
                icon: "serviceOps",
                title: (
                  <span className="ops-title">
                    <span>Service</span>Ops
                  </span>
                ),
                list: [
                  {
                    text: "BMC Helix Service Management",
                  },
                  {
                    text: "BMC Helix Operations Management",
                  },
                  {
                    text: "BMC AMI Ops",
                  },
                ],
              },
              {
                icon: "aiOps",
                title: (
                  <span className="ops-title">
                    <span>AI</span>Ops
                  </span>
                ),
                list: [
                  {
                    text: "BMC Helix Operations Management",
                  },
                  {
                    text: "BMC AMI Ops",
                  },
                ],
              },
              {
                icon: "devOps",
                title: (
                  <span className="ops-title">
                    <span>Dev</span>Ops
                  </span>
                ),
                list: [
                  {
                    text: "BMC AMI DevX",
                  },
                  {
                    text: "BMC Helix Service Management",
                  },
                  {
                    text: "BMC AMI Security",
                  },
                ],
              },
              {
                icon: "dataOps",
                title: (
                  <span className="ops-title">
                    <span>Data</span>Ops
                  </span>
                ),
                list: [
                  {
                    text: "Control-M",
                  },
                  {
                    text: "BMC Helix Control-M",
                    description:
                      "Including: Data & Cloud Integrations, Managed File Transfer, Automation API",
                  },
                  {
                    text: "BMC AMI Data",
                  },
                ],
              },
              {
                icon: "autonomousOps",
                title: (
                  <span className="ops-title">
                    <span>Autonomous</span>Ops
                  </span>
                ),
                list: [
                  {
                    text: "BMC Helix AIOps",
                  },
                  {
                    text: "BMC Helix Operations Management",
                  },
                  {
                    text: "Control-M",
                  },
                  {
                    text: "BMC Helix Control-M",
                    description:
                      "Including: Workflow Insights, Application Integrator, Service Level Agreements, Mainframe",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "service-ops",
        bg: "service-ops.jpg",
        name: "<span class='ops-title'><span>Service</span>Ops</span>",
        screens: [
          {
            type: "cover",
            data: {
              heading: "<span class='ops-title'><span>Service</span>Ops</span>",
              subheading:
                "Remove the barrier between service and operations, opening up greater collaboration between teams and departments to drive amazing user experiences.",
              buttonText: "Explore",
            },
          },
          {
            type: "introduction",
            data: {
              heading:
                "Driving better experiences for customers, employees, agents, and IT.",
              text: [
                "BMC ServiceOps provides a platform that breaks down silos, so that teams can collaborate with greater visibility into the data, manage change more effectively, and automate intelligently. Resulting in amazing user experiences that drive greater customer satisfaction, improve efficiency and lower costs.",
              ],
              audioImage: "headshot-service-ops.png",
              audioHeading: "Hear from an expert",
              audioText:
                "Katie Tierney, Sales and Presales Leader and Innovator, describes ServiceOps.",
              audioFile: "audio/service-ops.mp3",
            },
          },
          {
            type: "proofPoint",
            data: {
              text: "Huisman, designer and manufacturer of heavy construction equipment, saw a 30x increase in digital workplace adoption, 80% reduction in HR-related phone calls, 35% fewer email-based IT requests.",
            },
          },
          {
            type: "proofPoint",
            data: {
              text: "An e-commerce company cut down the percent of incidents caused by changes from over 90% to under 1%, with BMC Helix Discovery, CMDB, and dependency mapping.",
            },
          },
          {
            type: "solution",
            data: {
              id: "service-ops",
              name: (
                <span className="ops-title">
                  <span>Service</span>Ops
                </span>
              ),
              heading:
                "<span class='ops-title'><span>The Service</span>Ops <span>Solution</span></span>",
              bodyText:
                "Get the answers you need to start innovating your ServiceOps.",
              items: [
                {
                  name: "challenges",
                  icon: "exclamation",
                  heading: "Challenges",
                  bodyText:
                    "Roadblocks to adoption based on research and insights.",
                },
                {
                  name: "solutions",
                  icon: "target",
                  heading: "Solutions",
                  bodyText: "How we solve and prevent common challenges.",
                },
              ],
            },
          },
        ],
        solutions: [
          {
            type: "menu",
            items: [
              {
                title: "challenges",
                icon: "exclamation",
                text: "Challenges",
              },
              {
                title: "solutions",
                icon: "target",
                text: "Solutions",
              },
            ],
          },
          {
            type: "iconBoxes",
            name: "challenges",
            eyebrow:
              "<span class='ops-title'><span>The Service</span>Ops <span>Solution</span></span>",
            heading: "Market challenges",
            items: [
              {
                icon: "unity",
                title: "Need for unity",
                text: "Service Management & Operations Management must act cohesively to optimize the customer experience.<a data-tooltip-id='ops-tooltip-1' data-tooltip-content='Forrester, 2022' data-tooltip-place='top'><sup>1</sup></a>",
              },
              {
                icon: "platform",
                title: "Broader platform use",
                text: "Organizations want to automate not just the IT service desk, but the entire business.<a data-tooltip-id='ops-tooltip-2' data-tooltip-content='Gartner, 2023' data-tooltip-place='top'><sup>2</sup></a>",
              },
              {
                icon: "costChange",
                title: "Cost to change",
                text: "Many organizations feel locked into vendors whose platform spans the whole enterprise, making change very costly.<a data-tooltip-id='ops-tooltip-3' data-tooltip-content='Forrester, 2022' data-tooltip-place='top'><sup>3</sup></a>",
              },
              {
                icon: "support",
                title: "Quality support",
                text: "Employees and customers want high-quality human support, yet organizations are investing in virtual and automated solutions.<a data-tooltip-id='ops-tooltip-4' data-tooltip-content='Gartner, 2023' data-tooltip-place='top'><sup>4</sup></a>",
              },
            ],
          },
          {
            type: "toggles",
            name: "solutions",
            eyebrow:
              "<span class='ops-title'><span>The Service</span>Ops <span>Solution</span></span>",
            heading: "How we solve it",
            items: [
              {
                index: "01",
                title: "BMC Helix Management",
                bodyText:
                  "The BMC Helix Platform seamlessly integrates operational and service insights and creates actionable context for IT and the business, enhancing employee and customer experience, preventing service degradation and outages in the fastest and most cost-effective way.",
                toggles: [
                  {
                    title: "Service Management",
                    bodyText:
                      "Fast, accurate, cost-effective and smart service management delivery for the most complex, multi-cloud and hybrid organizations out there.",
                  },
                  {
                    title: "Operations Management",
                    bodyText:
                      "Integrating thousands of data points using smart AI is allowing you to keep a pulse on how all of your complex systems are performing and predicting issues, giving you a window into how customers and employees are experiencing your digital services.",
                  },
                ],
              },
              {
                index: "02",
                title: "BMC AMI AIOps",
                bodyText:
                  "Monitors, predicts, and prevents mainframe service issues before they occur, and gets to the root causes of the problems quickly.",
              },
            ],
          },
        ],
      },
      {
        id: "ai-ops",
        bg: "ai-ops.jpg",
        name: "<span class='ops-title'><span>AI</span>Ops</span>",
        screens: [
          {
            type: "cover",
            data: {
              heading: "<span class='ops-title'><span>AI</span>Ops</span>",
              subheading:
                "Combines big data and machine learning to automate IT operations processes.",
              buttonText: "Explore",
            },
          },
          {
            type: "introduction",
            data: {
              heading:
                "Automate IT processes to drive better business performance.",
              text: [
                "BMC AIOps uses AI and machine learning to analyze data from your management systems to create a single, unblinking view of your IT environment. By automating how you detect anomalies and proactively resolving issues, it frees up your people to focus on innovating and delivering new services faster than before.",
              ],
              audioImage: "headshot-ai-ops.png",
              audioHeading: "Hear from an expert",
              audioText:
                "Deepak Singla, Country Director–India and SAAR, on AIOps.",
              audioFile: "audio/ai-ops.mp3",
            },
          },
          {
            type: "proofPoint",
            data: {
              text: "A large European mutual insurance group used one console to manage its complex IT environment, saving 21 hrs/month with automated event remediation, and reducing costs by 22% in all application maintenance.",
            },
          },
          {
            type: "proofPoint",
            data: {
              text: "A major Swedish bank enabled a unified ‘single pane of glass’ monitoring that gives them a greater scope to incorporate not only monitoring, events, alerts and dashboards, but also deliver key insights, analytics, comprehensive service-based monitoring, intelligent automation, and integration.",
            },
          },
          {
            type: "solution",
            data: {
              id: "ai-ops",
              name: (
                <span className="ops-title">
                  <span>AI</span>Ops
                </span>
              ),
              heading:
                "The <span class='ops-title'><span>AI</span>Ops</span> Solution",
              bodyText:
                "Get the answers you need to automate IT processes and drive better business performance.",
              items: [
                {
                  name: "challenges",
                  icon: "exclamation",
                  heading: "Challenges",
                  bodyText:
                    "Barriers to adoption based on research and insights.",
                },
                {
                  name: "solutions",
                  icon: "target",
                  heading: "Solutions",
                  bodyText: "How we solve and prevent common challenges.",
                },
              ],
            },
          },
        ],
        solutions: [
          {
            type: "menu",
            items: [
              {
                title: "challenges",
                icon: "exclamation",
                text: "Challenges",
              },
              {
                title: "solutions",
                icon: "target",
                text: "Solutions",
              },
            ],
          },

          {
            type: "iconBoxes",
            name: "challenges",
            eyebrow:
              "<span class='ops-title'>The <span>AI</span>Ops <span>Solution</span></span>",
            heading: "Market challenges",
            items: [
              {
                icon: "money",
                title: "Paying double",
                text: "Organizations that transition to AIOps often keep legacy systems intact, resulting in wasted budget.<a data-tooltip-id='ops-tooltip-1' data-tooltip-content='CIO, 2021' data-tooltip-place='top'><sup>1</sup></a>",
              },
              {
                icon: "truck",
                title: "Speed to production",
                text: "Organizations are under pressure to bring projects and apps to production faster.<a data-tooltip-id='ops-tooltip-2' data-tooltip-content='Constellation Research, 2022' data-tooltip-place='top'><sup>2</sup></a>",
              },
              {
                icon: "visibility",
                title: "Lack of visibility",
                text: "Today’s organizations need greater visibility into their applications and infrastructure to power critical insights and services.<a data-tooltip-id='ops-tooltip-3' data-tooltip-content='Gartner, 2022' data-tooltip-place='top'><sup>3</sup></a>",
              },
              {
                icon: "charts",
                title: "Qualitative outcomes",
                text: "Mature organizations want contextual-ized business insights that are about making the business better.<a data-tooltip-id='ops-tooltip-4' data-tooltip-content='Gartner, 2022' data-tooltip-place='top'><sup>4</sup></a>",
              },
            ],
          },
          {
            type: "toggles",
            name: "solutions",
            eyebrow:
              "<span class='ops-title'>The <span>AI</span>Ops <span>Solution</span></span>",
            heading: "How we solve it",
            items: [
              {
                index: "01",
                title: "BMC Helix Operations Management",
                bodyText:
                  "BMC Helix Operations Management with AIOps is a fully integrated, cloud-native, observability and AIOps solution designed to tackle end-to-end management of challenging hybrid-cloud environments.",
                toggles: [
                  {
                    title: "AIOps",
                    bodyText:
                      "Leveraging best-of-breed algorithms, domain knowledge, observability data, and service models for root-cause isolation, noise reduction, outage prevention, and service degradation.",
                  },
                  {
                    title: "Discovery",
                    bodyText:
                      "Cloud-native discovery and dependency modeling system that provides instant visibility into hardware, software, and service dependencies across multi-cloud, hybrid, and on-premises environments.",
                  },
                  {
                    title: "Intelligent Automation",
                    bodyText:
                      "Automated triage and remediation actions based on business impact analysis.",
                  },
                ],
              },
              {
                index: "02",
                title: "BMC AMI AIOps",
                bodyText:
                  "Monitors, predicts, and prevents mainframe service issues before they occur, and gets to the root causes of the problems quickly.",
              },
            ],
          },
        ],
      },
      {
        id: "dev-ops",
        bg: "dev-ops.jpg",
        name: "<span class='ops-title'><span>Dev</span>Ops</span>",
        screens: [
          {
            type: "cover",
            data: {
              heading: "<span class='ops-title'><span>Dev</span>Ops</span>",
              subheading:
                "Enable a cultural shift where you’re able to develop innovative apps and deliver continuously.",
              buttonText: "Explore",
            },
          },
          {
            type: "introduction",
            data: {
              heading: "Innovate faster, deliver better.",
              text: [
                "Success isn’t just about moving fast or even building something great, but continuous delivery. At BMC, DevOps goes beyond collaboration and communications to help organizations automate and streamline their IT processes, improve visibility and control, and ensure high availability and performance of their applications and services.",
              ],
              audioImage: "headshot-dev-ops.png",
              audioHeading: "Hear from an expert",
              audioText:
                "Maurice Groeneveld, VP, Global Sales Mainframe Optimization + Transformation, on DevOps.",
              audioFile: "audio/dev-ops.mp3",
            },
          },
          {
            type: "proofPoint",
            data: {
              text: "Nedbank modernized its developer experience resulting in a 95% reduction in development and production time, a 75% faster release frequency, and 90% faster onboarding of new hires.",
            },
          },
          {
            type: "proofPoint",
            data: {
              text: "Folksam saved $170k per year through automated deployment processes; reduced deployment cycles by days—moving from quarterly releases to weekly sprints—further accelerating innovation.",
            },
          },
          {
            type: "solution",
            data: {
              id: "dev-ops",
              name: (
                <span className="ops-title">
                  <span>Dev</span>Ops
                </span>
              ),
              heading:
                "<span class='ops-title'>The <span>Dev</span>Ops <span>Solution</span></span>",
              bodyText:
                "Get the  answers you need to unleash dev team creativity and  build a best-in-class customer experience.",
              items: [
                {
                  name: "challenges",
                  icon: "exclamation",
                  heading: "Challenges",
                  bodyText:
                    "Barriers to adoption based on research and insights.",
                },
                {
                  name: "solutions",
                  icon: "target",
                  heading: "Solutions",
                  bodyText: "How we solve and prevent common challenges.",
                },
              ],
            },
          },
        ],
        solutions: [
          {
            type: "menu",
            items: [
              {
                title: "challenges",
                icon: "exclamation",
                text: "Challenges",
              },
              {
                title: "solutions",
                icon: "target",
                text: "Solutions",
              },
            ],
          },
          {
            type: "iconBoxes",
            name: "challenges",
            eyebrow:
              "<span class='ops-title'>The <span>Dev</span>Ops <span>Solution</span></span>",
            heading: "Market challenges",
            items: [
              {
                icon: "briefcase",
                title: "Talent gap",
                text: "The old guard of mainframe developers are retiring and the next generation are trained for DevOps and the cloud.<a data-tooltip-id='ops-tooltip-1' data-tooltip-content='Forrester, 2022' data-tooltip-place='top'><sup>1</sup></a>",
              },
              {
                icon: "tools",
                title: "Existing tools",
                text: "Sub-par tools lack modern capabilities and significantly slow deployment.<a data-tooltip-id='ops-tooltip-2' data-tooltip-content='Forrester, 2022' data-tooltip-place='top'><sup>2</sup></a>",
              },
              {
                icon: "performance",
                title: "Slow to modernize",
                text: "Modernization is slowed by isolated dev groups reluctant to get off the mainframe.<a data-tooltip-id='ops-tooltip-3' data-tooltip-content='Forrester, 2022' data-tooltip-place='top'><sup>3</sup></a>",
              },
              {
                icon: "bulb",
                title: "Accelerating innovation",
                text: "Pressure to deploy code faster and more frequently due to increased reliance on mobile applications.<a href='https://www.cybersecuritydive.com/news/organizations-deploy-code-faster/644292/' target='_blank' data-tooltip-id='ops-tooltip-4' data-tooltip-content='CyberSecurity Dive, 2023' data-tooltip-place='top'><sup>4</sup></a>",
              },
            ],
          },
          {
            type: "toggles",
            name: "solutions",
            eyebrow:
              "<span class='ops-title'>The <span>Dev</span>Ops <span>Solution</span></span>",
            heading: "How we solve it",
            items: [
              {
                index: "01",
                title: "BMC AMI DevX",
                bodyText:
                  "Empowers the next generation of developers to make the mainframe as adaptive as any other platform, with unparalleled agile application development, testing, and delivery.",
              },
              {
                index: "02",
                title: "BMC Helix Service Management",
                bodyText:
                  "Agile DevOps out-of-the-box support in BMC Helix allows developers to release code faster with higher quality and work in their choice of DevOps tools like Jira, Jenkins while ensuring Enterprise governance and compliance.",
              },
              {
                index: "03",
                title: "BMC AMI Security",
                bodyText:
                  "Continuously protects against vulnerabilities, malicious actions, and data theft.",
              },
            ],
          },
        ],
      },
      {
        id: "data-ops",
        bg: "data-ops.jpg",
        name: "<span class='ops-title'><span>Data</span>Ops</span>",
        screens: [
          {
            type: "cover",
            data: {
              heading: "<span class='ops-title'><span>Data</span>Ops</span>",
              subheading:
                "Enables the modern business to unlock greater business value from data—rapidly, continuously, and at scale.",
              buttonText: "Explore",
            },
          },
          {
            type: "introduction",
            data: {
              heading: "Making your mainframe dance and deliver.",
              text: [
                "BMC DataOps is how the customer data on your mainframe meets the expectations of today’s customer experience.",
                "Streamlining and automating data management to ensure always-on availability and end-to-end visibility. Making your mainframe as nimble as your other platforms.",
              ],
              audioImage: "headshot-data-ops.png",
              audioHeading: "Hear from an expert",
              audioText: "Dave Tobias, Global Account Manager, on DataOps.",
              audioFile: "audio/data-ops.mp3",
            },
          },
          {
            type: "proofPoint",
            data: {
              text: "Railinc automates daily data capture and processing of 11 million data points for 40,000 locomotives, 1.6 million railcars, over 140,000 miles of railroad network, with 50TB of data, planned for 100TB in 3 years.",
            },
          },
          {
            type: "proofPoint",
            data: {
              text: "Carfax seamlessly orchestrates 100,000+ data sources to get new business services into production in just minutes instead of months with better collaboration between IT and business leaders, resulting in improved ROI.",
            },
          },
          {
            type: "solution",
            data: {
              id: "data-ops",
              name: "<span class='ops-title'><span>Data</span>Ops</span>",
              heading:
                "<span class='ops-title'>The <span>Data</span>Ops <span>Solution</span></span>",
              bodyText:
                "Get the DataOps answers you need to uncover new opportunities, quickly respond to issues, and even predict the future.",
              items: [
                {
                  name: "challenges",
                  icon: "exclamation",
                  heading: "Challenges",
                  bodyText:
                    "Barriers to adoption based on research and insights.",
                },
                {
                  name: "solutions",
                  icon: "target",
                  heading: "Solutions",
                  bodyText: "How we solve and prevent common challenges.",
                },
              ],
            },
          },
        ],
        solutions: [
          {
            type: "menu",
            items: [
              {
                title: "challenges",
                icon: "exclamation",
                text: "Challenges",
              },
              {
                title: "solutions",
                icon: "target",
                text: "Solutions",
              },
            ],
          },
          {
            type: "iconBoxes",
            name: "challenges",
            eyebrow:
              "<span class='ops-title'>The <span>Data</span>Ops <span>Solution</span></span>",
            heading: "Market challenges",
            items: [
              {
                icon: "reliability",
                title: "Production reliability",
                text: "Data pipelines in production need to support outcomes across an increasingly complex application, data, and infrastructure ecosystem.<a href='https://www.gartner.com/smarterwithgartner/how-dataops-amplifies-data-and-analytics-business-value' target='_blank' data-tooltip-id='ops-tooltip-1' data-tooltip-content='Gartner, 2020' data-tooltip-place='top'><sup>1</sup></a>",
              },
              {
                icon: "automation",
                title: "Automation siloes",
                text: "Teams aren’t coordinating automation efforts, creating slow time-to-market, inability to meet production SLAs, and costly manual intervention and support.<a href='https://www.forrester.com/what-it-means/ep292-chief-automation-officer/' target='_blank' data-tooltip-id='ops-tooltip-2' data-tooltip-content='Forrester, 2022' data-tooltip-place='top'><sup>2</sup></a>",
              },
              {
                icon: "operational",
                title: "Operational value of data",
                text: "Businesses need to reduce the cycle time to access ready-to-use data, to drive more value for consumers.<a data-tooltip-id='ops-tooltip-3' data-tooltip-content='Gartner, 2022' data-tooltip-place='top'><sup>3</sup></a>",
              },
            ],
          },
          {
            type: "toggles",
            name: "solutions",
            eyebrow:
              "<span class='ops-title'>The <span>Data</span>Ops <span>Solution</span></span>",
            heading: "How we solve it",
            items: [
              {
                index: "01",
                title: "Control-M & BMC Helix Control-M",
                bodyText:
                  "Control-M helps the Autonomous Digital Enterprise accelerate data driven outcomes by orchestrating data pipelines within complex enterprise business applications.",
                toggles: [
                  {
                    title: "Data & Cloud Integrations",
                    bodyText:
                      "Provide continuous delivery of out-of-the-box integrations to cloud services, data platforms, and open-source tools.",
                  },
                  {
                    title: "Control-M Managed File Transfer",
                    bodyText:
                      "Reduce risk and deliver business services faster by automating internal and external file transfers in a single view with related application workflows in hybrid environments.",
                  },
                  {
                    title: "Control-M Automation API",
                    bodyText:
                      "Integrate data pipeline management into the application development processes, providing inherent governance without the burden of handoffs and helpdesk tickets.",
                  },
                ],
              },
              {
                index: "02",
                title: "BMC AMI Data",
                bodyText: (
                  <>
                    Offers a complete and automated set of IBM<sup>®</sup> Db2
                    <sup>®</sup> and IBM<sup>®</sup> IMS/ESA<sup>®</sup>{" "}
                    solutions to optimize mainframe transaction and data
                    management so you can improve availability of critical
                    business services.
                  </>
                ),
              },
            ],
          },
        ],
      },
      {
        id: "autonomous-ops",
        bg: "autonomous-ops.jpg",
        name: "<span class='ops-title'><span>Autonomous</span>Ops</span>",
        screens: [
          {
            type: "cover",
            data: {
              heading:
                "<span class='ops-title'><span>Autonomous</span>&ZeroWidthSpace;Ops</span>",
              subheading:
                "Gives modern business the freedom to innovate with new technologies by integrating, automating and orchestrating complex application, and data workflows across the hybrid IT landscape, so teams can deliver business outcomes at enterprise speed and scale.",
              buttonText: "Explore",
            },
          },
          {
            type: "introduction",
            data: {
              heading: "Automating change, so you can focus on innovation.",
              text: [
                "Critical to how fast you move as an enterprise, is how well you can integrate, automate, and orchestrate workloads. With BMC AutonomousOps, you have a collaborative platform for leveraging AI to make your systems self-healing, predicting and resolving maintenance issues automatically.",
                "By automating mundane IT tasks, you get more out of the teams you have.",
              ],
              audioImage: "headshot-autonomous-ops.png",
              audioHeading: "Hear from an expert",
              audioText:
                "Gunal Kannan, AVP, Tech Strategy and Advocacy, on AutonomousOps.",
              audioFile: "audio/autonomous-ops.mp3",
            },
          },
          {
            type: "proofPoint",
            data: {
              text: "Global financial services firm Raymond James supports over 6,500 financial advisors for 2.7 million customers, with 2 million job executions per month, absorbed 42% more job executions with no increase in headcount and delivered audit reports 96% faster.",
            },
          },
          {
            type: "proofPoint",
            data: {
              text: "Carrefour Argentina opened 540 new city-center stores in 5 years and saw an 11.8x increase in data transfers in order to manage the resulting changes to its pricing and supply chain—all with no increase in personnel.",
            },
          },
          {
            type: "solution",
            data: {
              id: "autonomous-ops",
              name: "<span class='ops-title'><span>Autonomous</span>Ops</span>",
              heading:
                "<span class='ops-title'>The <span>Autonomous</span>Ops <span>Solution</span></span>",
              bodyText:
                "Get the answers you need to innovate with new technologies and deliver better business outcomes at enterprise speed and scale.",
              items: [
                {
                  name: "challenges",
                  icon: "exclamation",
                  heading: "Challenges",
                  bodyText:
                    "Barriers to adoption based  on research and insights.",
                },
                {
                  name: "solutions",
                  icon: "target",
                  heading: "Solutions",
                  bodyText: "How we solve and prevent common challenges.",
                },
              ],
            },
          },
        ],
        solutions: [
          {
            type: "menu",
            items: [
              {
                title: "challenges",
                icon: "exclamation",
                text: "Challenges",
              },
              {
                title: "solutions",
                icon: "target",
                text: "Solutions",
              },
            ],
          },
          {
            type: "iconBoxes",
            name: "challenges",
            eyebrow:
              "<span class='ops-title'>The <span>Autonomous</span>Ops <span>Solution</span></span>",
            heading: "Market challenges",
            items: [
              {
                icon: "atom",
                title: "Business modernization",
                text: "The pressure to deploy new digital products quickly has created an orchestration bottleneck between teams and functions.<a href='https://www.mckinsey.com/~/media/McKinsey/Business%20Functions/Operations/Our%20Insights/The%20imperatives%20for%20automation%20success/The-imperatives-for-automation-success.pdf' target='_blank' data-tooltip-id='ops-tooltip-1' data-tooltip-content='McKinsey, 2020' data-tooltip-place='top'><sup>1</sup></a>",
              },
              {
                icon: "cloud",
                title: "Cloud complexity",
                text: "Without an orchestration framework, running multiple cloud IaaS, container, and PaaS models limits agility and innovation.<a href='https://www.gartner.com/smarterwithgartner/6-steps-for-planning-a-cloud-strategy' target='_blank' data-tooltip-id='ops-tooltip-2' data-tooltip-content='Gartner, 2022' data-tooltip-place='top'><sup>2</sup></a>",
              },
              {
                icon: "adoption",
                title: "Rapid adoption of new technologies",
                text: "Adopting new application, data, and infrastructure tools in an already complex tech stack can be costly to manage, support, and secure.<a href='https://www.gartner.com/en/newsroom/press-releases/2022-07-12-gartner-finds-that-majority-of-technology-purchases-come-with-high-degree-of-regret' target='_blank' data-tooltip-id='ops-tooltip-3' data-tooltip-content='Gartner, 2022' data-tooltip-place='top'><sup>3</sup></a>",
              },
            ],
          },
          {
            type: "toggles",
            name: "solutions",
            eyebrow:
              "<span class='ops-title'>The <span>Autonomous</span>Ops <span>Solution</span></span>",
            heading: "How we solve it",
            items: [
              {
                index: "01",
                title: "Control-M & BMC Helix Control-M",
                bodyText:
                  "Control-M helps the ADE automate operational and business workflows.",
                toggles: [
                  {
                    title: "Control-M Workflow Insights",
                    bodyText:
                      "Empowers operational leaders to improve the performance of application and data workflows that power critical business service outcomes.",
                  },
                  {
                    title: "Control-M Application Integrator",
                    bodyText:
                      "Lets you create custom integrations to any application or database technology, without requiring custom coding or programming skills.",
                  },
                  {
                    title: "SLA Management",
                    bodyText:
                      "Gives you real-time insight before business services are impacted, minimizing the risk of SLA violations.",
                  },
                  {
                    title: "Mainframe",
                    bodyText:
                      "Simplifies and provides full integration of mainframe workload functions into enterprise workflows, creating a single, coherent management and orchestration process.",
                  },
                ],
              },
              {
                index: "02",
                title: "BMC Helix AIOps & Operations Management",
                bodyText:
                  "BMC Helix AIOps is the industry-leading solution that uses Advanced AI including LLM & Generative AI to automate root cause, predict issues, and auto-remediate. Combined, DevOps and IT drive productivity and the highest level of customer experience and service level agreements.",
              },
            ],
          },
        ],
      },
    ],
  },
};
