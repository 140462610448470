export const ArrowRight = ({ className }) => (
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    className={className}
  >
    <path
      d="M1.83984 8.74C1.14397 8.74 0.579844 9.30412 0.579844 10C0.579844 10.6959 1.14397 11.26 1.83984 11.26V8.74ZM22.0508 10.891C22.5429 10.3989 22.5429 9.60111 22.0508 9.10905L14.0322 1.09046C13.5401 0.598395 12.7424 0.598395 12.2503 1.09046C11.7582 1.58252 11.7582 2.3803 12.2503 2.87236L19.3779 10L12.2503 17.1276C11.7582 17.6197 11.7582 18.4175 12.2503 18.9095C12.7424 19.4016 13.5401 19.4016 14.0322 18.9095L22.0508 10.891ZM1.83984 11.26H21.1598V8.74H1.83984V11.26Z"
      fill="currentColor"
    />
  </svg>
);
