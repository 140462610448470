export const IconCircleCheckmark = ({ className }) => (
  <svg
    className={className}
    width="40"
    height="44"
    viewBox="0 0 40 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39 24C39 34.4934 30.4934 43 20 43C9.50659 43 1 34.4934 1 24C1 13.5066 9.50659 5 20 5C30.4934 5 39 13.5066 39 24Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.75 19L18.75 30.25L12.5 24"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
