export const IconDeliver = ({ className }) => (
  <svg
    className={className}
    width="73"
    height="42"
    viewBox="0 0 73 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.3933 20.4758V18.8342"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5343 21.002H29.3072"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.0474 13.516C54.9161 13.3847 54.7192 13.2534 54.5878 13.0564C52.4866 11.2178 49.7943 10.1015 46.8395 10.1015C46.4455 10.1015 46.0515 10.1016 45.7232 10.1672C44.3443 5.1111 39.8134 1.36816 34.2977 1.36816C28.2566 1.36816 23.2661 5.89907 22.5438 11.8088C18.407 13.1221 15.3208 16.8649 14.8612 21.3301C14.8612 21.5271 14.8612 21.6583 14.7955 21.8553"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.1088 27.7652C16.1745 27.8965 16.2401 28.0279 16.3057 28.1592C16.4371 28.3562 16.5027 28.5532 16.6341 28.6845C18.6696 31.7051 22.0842 33.6749 25.9584 33.6749H37.5809H46.8395C53.3402 33.6749 58.5933 28.4219 58.5933 21.9211C58.5933 21.0675 58.5276 20.2138 58.3306 19.4259C58.3306 19.2945 58.265 19.1633 58.265 19.032"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6587 34.4626L7.57248 30.7854"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.81981 29.8006C10.3957 27.962 10.1988 25.1385 8.29455 23.5625C6.45596 21.9866 3.63241 22.1836 2.05648 24.0879C0.480546 25.9921 0.67749 28.75 2.58174 30.326C4.42033 31.9019 7.17822 31.6392 8.81981 29.8006Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3862 39.7812C16.6338 38.3366 16.4368 36.1698 14.9922 34.9222C13.5476 33.6746 11.3807 33.8715 10.133 35.3161C8.88543 36.7607 9.08248 38.9277 10.5271 40.1753C11.9717 41.4229 14.1385 41.2258 15.3862 39.7812Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.548 7.14648L65.0939 10.2984"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.1089 11.4804C62.7956 13.5816 63.4522 16.2737 65.5535 17.587C67.6547 18.9003 70.347 18.2437 71.6602 16.1424C72.9735 14.0412 72.3168 11.3489 70.2156 10.0356C68.1143 8.72236 65.3565 9.37913 64.1089 11.4804Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.0321 2.61541C55.0472 4.25701 55.5725 6.35822 57.1484 7.34318C58.79 8.32814 60.8913 7.80286 61.8762 6.22693C62.8612 4.58533 62.3359 2.48395 60.76 1.499C59.184 0.514037 57.0171 1.03948 56.0321 2.61541Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.6684 16.7987L63.5179 14.6975"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.3646 18.1124L50.3851 17.4558"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.3619 23.4964L24.3824 22.9055"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.73927 25.9917L18.407 24.1531"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.2168 29.8664C31.4739 29.9977 31.7365 27.3711 31.7365 27.3711C26.0238 25.3355 27.3371 21.1331 27.3371 21.1331C23.4629 14.7637 30.292 12.4655 30.292 12.4655C31.9992 9.6419 35.2168 9.77317 35.2168 9.77317C35.2168 9.77317 38.4343 9.6419 40.1415 12.4655C40.1415 12.4655 46.9706 14.7637 43.0964 21.1331C43.0964 21.1331 44.4097 25.3355 38.6969 27.3711C38.6969 27.3711 38.9596 29.9977 35.2168 29.8664Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.545 17.3242C35.545 17.3242 38.5656 17.8495 39.2222 15.6826"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.2373 13.9754C38.2373 13.9754 38.9597 12.5965 40.4043 12.8592"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.5357 18.3752C40.5357 18.3752 41.4549 19.9512 40.273 21.3301"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.4249 22.2488C43.4249 22.2488 43.1622 20.4101 40.9953 20.2131"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.0145 16.0113C31.0145 16.5366 30.6205 16.9305 30.0952 16.9305C29.5698 16.9305 29.1758 16.5366 29.1758 16.0113C29.1758 15.486 29.5698 15.092 30.0952 15.092C30.5548 15.092 31.0145 15.486 31.0145 16.0113Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.3127 21.3951C33.3127 21.9204 32.9187 22.3145 32.3934 22.3145C31.8681 22.3145 31.4741 21.9204 31.4741 21.3951C31.4741 20.8697 31.8681 20.4758 32.3934 20.4758C32.9187 20.4758 33.3127 20.8697 33.3127 21.3951Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.9993 26.5828C32.5071 26.5828 32.9187 26.1711 32.9187 25.6634C32.9187 25.1557 32.5071 24.7441 31.9993 24.7441C31.4916 24.7441 31.08 25.1557 31.08 25.6634C31.08 26.1711 31.4916 26.5828 31.9993 26.5828Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.545 22.84C35.545 22.84 38.0402 22.1834 38.9595 23.7594"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.6915 18.7032H30.0293V17.5212"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.3933 20.4758V18.8342"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5343 21.002H29.3072"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.2825 29.8004V9.6416"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
