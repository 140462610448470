import cn from "classnames";
import { useSolutionsModal } from "../../../../context/SolutionsContext";
import { Icon } from "../../../Icon";
import styles from "./Solution.module.scss";

export const Solution = ({ id, heading, productName, bodyText, items }) => {
  const {
    setCurrentSolutionsModal,
    setCurrentSolutionsTab,
    currentSolutionsTab,
  } = useSolutionsModal();

  const onClickHandler = (tab) => {
    setCurrentSolutionsModal(id);
    setCurrentSolutionsTab(tab);
  };

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <div className={styles.head}>
          <span
            className={cn(styles.badge, styles.large)}
            dangerouslySetInnerHTML={{ __html: productName }}
          />
        </div>
        <div className={styles.content}>
          {heading && (
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: heading }}
            />
          )}
          {bodyText && (
            <p
              className={styles.bodyText}
              dangerouslySetInnerHTML={{ __html: bodyText }}
            />
          )}
          {items && (
            <div
              className={cn(styles.solutionsBoxes, {
                [styles.two]: items?.length === 2,
              })}
            >
              {items.map((item, index) => (
                <div
                  key={index}
                  onClick={() => onClickHandler(item.name)}
                  className={cn(styles.solutionsBox, {
                    [styles.active]: currentSolutionsTab === item.name,
                  })}
                >
                  <Icon icon={item.icon} className={styles.solutionsBoxIcon} />
                  <div className={styles.solutionsBoxContent}>
                    {item?.heading && (
                      <h3 className={styles.solutionsBoxTitle}>
                        {item.heading}
                      </h3>
                    )}
                    {item?.bodyText && (
                      <p className={styles.solutionsBoxText}>{item.bodyText}</p>
                    )}
                  </div>
                  <div className={styles.solutionsBoxArrowWrapper}>
                    <Icon
                      icon="arrowRight"
                      className={cn(styles.solutionsBoxArrow, styles.normal)}
                    />
                    <Icon
                      icon="arrowPopup"
                      className={cn(styles.solutionsBoxArrow, styles.hover)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
