import React from "react";
import cx from "classnames";
import { Icon } from "../Icon";
import styles from "./ProductBox.module.scss";

export const ProductBox = ({ className, icon, title, list }) => {
  return (
    <div className={cx(className, styles.root)}>
      <div className={styles.header}>
        {icon && <Icon icon={icon} className={styles.icon} />}
        {title && <h3 className={styles.title}>{title}</h3>}
      </div>
      {list && (
        <ul className={styles.list}>
          {list.map((item, index) => (
            <li key={index} className={styles.listItem}>
              {item?.text && (
                <span className={styles.listText}>{item.text}</span>
              )}
              {item?.description && (
                <li className={styles.listDescription}>
                  {item.description}
                </li>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
