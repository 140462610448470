import { useRef, useState, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Screen } from "../ScreenInternal";
import { SolutionsModal } from "./SolutionsModal";
import styles from "./ProductMobile.module.scss";
import classNames from "classnames";
import { useWindowHeight } from "@react-hook/window-size";
import { ArrowDown } from "../Icons/ArrowDown";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export const ProductMobile = ({
  id,
  name,
  bg,
  screens,
  solutions,
  nextName,
  isActive,
  setActiveProduct,
  productIndex,
  setInViewProduct,
  scrollToNext,
  scrollToFirst,
  isLast,
}) => {
  const rootRef = useRef(null);
  const innerRef = useRef(null);
  const scrollWrapperRef = useRef(null);
  const bgImageRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const windowHeight = useWindowHeight();

  useEffect(() => {
    rootRef.current.scrollTop = 0;
  }, []);

  useEffect(() => {
    const ctx = gsap.context(() => {
      ScrollTrigger.create({
        scroller: innerRef.current,
        trigger: scrollWrapperRef.current,
        start: "top top",
        end: "bottom bottom",
        onLeaveBack: () => {
          setActiveProduct((currentActiveProduct) => {
            if (currentActiveProduct === productIndex) {
              return null;
            } else {
              return currentActiveProduct;
            }
          });
        },
        onLeave: () => {
          isLast ? scrollToFirst() : scrollToNext();
          setActiveProduct((currentActiveProduct) => {
            if (currentActiveProduct === productIndex) {
              return null;
            } else {
              return currentActiveProduct;
            }
          });
        },
        // markers: true,
      });
    }, rootRef);

    return () => ctx.revert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIndex, setActiveProduct]);

  useEffect(() => {
    if (isActive) {
      gsap.to(window, {
        duration: 0.7,
        scrollTo: rootRef.current,
        ease: "power2.inOut",
      });
      gsap.to(innerRef.current, {
        duration: 0.7,
        scrollTo: { y: windowHeight },
        ease: "power2.inOut",
      });
    } else {
      gsap.to(innerRef.current, {
        duration: 0.7,
        delay: 1,
        scrollTo: { y: 0 },
        ease: "power2.inOut",
      });
      setIsPlaying(false);
      window.Howler.stop();
    }
  }, [isActive, windowHeight]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: rootRef.current,
        start: "-50% top",
        end: "50% top",
        onEnter: () => setInViewProduct(productIndex),
        onEnterBack: () => setInViewProduct(productIndex),
        // markers: true
      });
    });

    return () => ctx.revert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInViewProduct]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        bgImageRef.current,
        {
          y: "-20%",
        },
        {
          y: "20%",
          ease: "none",
          scrollTrigger: {
            trigger: rootRef.current,
            start: "top bottom",
            end: "bottom top",
            scrub: 1,
            // markers: true
          },
        }
      );
    });

    return () => ctx.revert();
  }, []);

  return (
    <section
      className={classNames(styles.scrollSectionOuter, {
        [styles.active]: isActive,
      })}
      ref={rootRef}
    >
      <div className={styles.scrollSectionInner} ref={innerRef}>
        <div className={styles.scrollWrapper} ref={scrollWrapperRef}>
          {screens?.map((screen, index) => (
            <div className={styles.scrollSection} key={index}>
              <Screen
                productName={name}
                screen={screen}
                onButtonClick={() => setActiveProduct(productIndex)}
                isPlaying={isPlaying && isActive}
                setIsPlaying={setIsPlaying}
              />
            </div>
          ))}
          <div className={styles.scrollSection} key={"continue"}>
            <div className={styles.continue}>
              <div>{isLast ? "Back to " : "Continue to"}</div>
              <div dangerouslySetInnerHTML={{ __html: nextName }} />
              <div
                className={styles.nextIcon}
                onClick={() => (isLast ? scrollToFirst() : scrollToNext())}
              >
                <ArrowDown />
              </div>
            </div>
          </div>
        </div>
        {/* <div ref={exitTriggerRef} style={{ height: "20vh" }} /> */}
      </div>
      <div className={styles.bg}>
        <div className={styles.bgInner}>
          <img
            src={require(`../../images/backgrounds/${bg}`)}
            alt={name}
            ref={bgImageRef}
          />
        </div>
      </div>
      <SolutionsModal id={id} data={solutions} />
    </section>
  );
};
