/* eslint-disable jsx-a11y/alt-text */
import { useRef, useLayoutEffect } from "react";
import cx from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styles from "./Clouds.module.scss";
import cloudImage1 from "../../../images/cloud1.png";
import cloudImage2 from "../../../images/cloud2.png";
import cloudImage3 from "../../../images/cloud3.png";
import cloudsImage from "../../../images/clouds.png";

gsap.registerPlugin(ScrollTrigger);

const cloudImages = [cloudImage1, cloudImage2, cloudImage3]

export const Clouds = ({ className, multi = false, cloudId = 0 }) => {
  const rootContainer = useRef();
  const cloud = useRef();
  const cloudContainer = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.set(cloud.current, {
        y: "100%",
      });
      gsap.to(cloud.current, {
        y: "-100%",
        scrollTrigger: {
          trigger: cloudContainer.current,
          start: "top bottom",
          end: "bottom top",
          scrub: 1,
        },
        ease: "none",
      });
    }, rootContainer);

    return () => ctx.revert();
  }, []);

  return (
    <div className={cx(className, styles.root)} ref={rootContainer}>
      <div className={styles.clouds} ref={cloudContainer}>
        {multi ? (
          <img className={styles.cloudsImage} src={cloudsImage} ref={cloud} />
        ): (
          <img src={cloudImages[cloudId]} ref={cloud} />
        )}
      </div>
    </div>
  );
};
