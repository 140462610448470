export const IconTarget = ({ className }) => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M20.2497 39.4994C16.4425 39.4994 12.7207 38.3704 9.55514 36.2552C6.38955 34.14 3.92227 31.1337 2.4653 27.6162C1.00834 24.0988 0.627132 20.2283 1.36989 16.4943C2.11264 12.7602 3.946 9.33023 6.63811 6.63811C9.33023 3.946 12.7602 2.11264 16.4943 1.36989C20.2283 0.627132 24.0988 1.00834 27.6162 2.4653C31.1337 3.92227 34.14 6.38955 36.2552 9.55514C38.3704 12.7207 39.4994 16.4425 39.4994 20.2497M20.2497 31.4495C18.0346 31.4495 15.8692 30.7927 14.0274 29.562C12.1856 28.3314 10.7501 26.5822 9.90242 24.5357C9.05473 22.4892 8.83293 20.2373 9.26508 18.0647C9.69723 15.8922 10.7639 13.8966 12.3302 12.3302C13.8966 10.7639 15.8922 9.69723 18.0647 9.26508C20.2373 8.83293 22.4892 9.05473 24.5357 9.90242C26.5822 10.7501 28.3314 12.1856 29.562 14.0274C30.7927 15.8692 31.4495 18.0346 31.4495 20.2497"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.2462 23.0543C21.7926 23.0543 23.0462 21.8007 23.0462 20.2543C23.0462 18.7079 21.7926 17.4543 20.2462 17.4543C18.6999 17.4543 17.4463 18.7079 17.4463 20.2543C17.4463 21.8007 18.6999 23.0543 20.2462 23.0543Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.2392 27.2496L26.1294 26.1296L27.1094 38.3094M42.9991 42.9994L26.1294 26.1296L42.9991 42.9994Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
