export const IconMoney = ({ className }) => (
  <svg
    className={className}
    width="46"
    height="32"
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 28.5V4.08C1 4.08 3.93333 1 12.7333 1C21.5333 1 23.88 5.4 33.2667 5.4C37.2401 5.29296 41.1833 4.67682 45 3.56667V28.5C45 28.5 41.7733 30.3333 34.7333 30.3333C27.6933 30.3333 22.12 25.9333 14.2 25.9333C9.6701 25.8696 5.17597 26.7435 1 28.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.2598 15.3726C30.9932 19.406 28.2798 22.706 24.3198 22.706C20.3598 22.706 16.5465 19.406 15.8132 15.3726C15.0798 11.3393 17.7932 8.03931 21.7532 8.03931C25.7132 8.03931 29.5998 11.266 30.2598 15.3726Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.26 1.28271C8.43025 2.33307 8.36989 3.4079 8.0831 4.43259C7.79631 5.45728 7.28996 6.40728 6.5992 7.21666C5.90844 8.02603 5.04982 8.67539 4.08293 9.11966C3.11605 9.56392 2.06406 9.79245 1 9.78938M1 20.0561C2.77788 20.0587 4.49425 20.7072 5.82974 21.8808C7.16522 23.0544 8.02888 24.6733 8.26 26.4361M45 11.256C43.2548 11.2611 41.5651 10.6436 40.2344 9.51448C38.9037 8.38539 38.0192 6.81874 37.74 5.09605M37.8133 30.1761C37.725 29.7413 37.6759 29.2996 37.6667 28.856C37.6667 26.9111 38.4393 25.0459 39.8146 23.6706C41.1898 22.2953 43.0551 21.5227 45 21.5227"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
