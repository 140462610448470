import { useRef, useEffect, useLayoutEffect } from "react";
import { Icon } from "../../components/Icon";
import { VideoQuoteCarousel } from "../../components/VideoQuoteCarousel/VideoQuoteCarousel";
import buildingImage from "../../images/landing/skyscraper-desktop-2.jpg";
import buildingImageMobile from "../../images/landing/skyscraper-mobile.jpg";
import cloudImage from "../../images/cloud2.png";
import { useWindowWidth } from "@react-hook/window-size";
import { Footer } from "../../components/Footer/Footer";
import { Section } from "../../components/Section/Section";
import { Logo } from "../../components/Icons/Logo";
import styles from "./Landing.module.scss";
import classNames from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function LandingPage() {
  const windowWidth = useWindowWidth();
  // const doorLeft = useRef(null);
  // const doorRight = useRef(null);
  const fog = useRef(null);
  const building = useRef(null);
  const cloud = useRef(null);
  const container = useRef(null);
  const title = useRef(null);
  const cdoBox = useRef(null);
  const cdoBg = useRef(null);
  const contactContainer = useRef(null);
  const contactBg = useRef(null);

  const introAnimation = () => {
    gsap.fromTo(
      building.current,
      {
        y: "30vh",
      },
      {
        y: 0,
        duration: 1.5,
        ease: "power2.inOut",
      }
    );

    gsap.fromTo(
      cloud.current,
      {
        y: "30vh",
      },
      {
        y: 0,
        duration: 1.5,
        delay: 0.25,
        ease: "power2.inOut",
      }
    );

    gsap.fromTo(
      title.current,
      {
        alpha: 0,
        y: 50,
      },
      {
        alpha: 1,
        y: 0,
        duration: 1.25,
        delay: 1.5,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      cdoBox.current,
      {
        alpha: 0,
        y: 50,
      },
      {
        alpha: 1,
        y: 0,
        duration: 1,
        delay: 1.75,
        ease: "power2.out",
      }
    );

    gsap.fromTo(
      fog.current,
      {
        alpha: 1,
      },
      {
        alpha: 0,
        duration: 2.5,
        ease: "power3.inOut",
      }
    );

    // gsap.fromTo(
    //   doorLeft.current,
    //   {
    //     x: '100%'
    //   },
    //   {
    //     x: 0,
    //     duration: 1,
    //     ease: "power2.inOut",
    //   }
    // )

    // gsap.fromTo(
    //   doorRight.current,
    //   {
    //     x: '-100%'
    //   },
    //   {
    //     x: 0,
    //     duration: 1,
    //     ease: "power2.inOut",
    //   }
    // )
  };

  useEffect(() => {
    introAnimation();
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        cloud.current,
        {
          x: "30%",
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: container.current,
            start: "top top",
            end: "bottom top",
            scrub: 1,
            // markers: true,
          },
        }
      );
    });

    return () => ctx.revert();
  });

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        cdoBg.current,
        {
          y: "-20%",
        },
        {
          y: "20%",
          ease: "none",
          scrollTrigger: {
            trigger: cdoBox.current,
            start: "top bottom",
            end: "bottom top",
            scrub: 1,
          },
        }
      );
    });

    return () => ctx.revert();
  }, []);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        contactBg.current,
        {
          y: "-20%",
        },
        {
          y: "20%",
          ease: "none",
          scrollTrigger: {
            trigger: contactContainer.current,
            start: "top bottom",
            end: "bottom top",
            scrub: 1,
          },
        }
      );
    });

    return () => ctx.revert();
  }, []);

  return (
    <>
      {/* <div className={styles.doors}>
        <div className={styles.doorLeft} ref={doorLeft}></div>
        <div className={styles.doorRight} ref={doorRight}></div>
      </div> */}
      <div className={styles.fog} ref={fog}></div>
      <div className={styles.skyscraper} ref={container}>
        <Logo className={styles.logo} />
        <div className={styles.buildingContainer}>
          <img
            src={windowWidth > 1025 ? buildingImage : buildingImageMobile}
            className={styles.building}
            ref={building}
            alt=""
          />
          <img src={cloudImage} className={styles.cloud} ref={cloud} alt="" />
          <img src={cloudImage} className={styles.clouds} alt="" />
        </div>
        <Section>
          <div ref={title}>
            <h2 className={styles.skyscraperHeading}>
              In the age of AI, it’s time to build an Autonomous Digital
              Enterprise.
            </h2>
            <p className={styles.skyscraperText}>
              Activate data and AI across your digital operations to transform
              everything.
            </p>
            <a
              href="https://connecteddigitalops.bmc.com/skyscraper"
              className={styles.button}
            >
              Get started
            </a>
          </div>
          <div className={styles.cdoBox} ref={cdoBox}>
            <div className={styles.cdoBg} ref={cdoBg}></div>
            <h2 className={styles.cdoBoxHeadline}>
              It all starts with{" "}
              <span className="ops-title">
                <span>ConnectedDigital</span>Ops
              </span>
            </h2>
            <p className={styles.cdoBoxText}>
              BMC makes the most of your technology, connecting and optimizing
              digital operations to fuel efficiency, intelligence and
              innovation.
            </p>
            <a
              href="https://connecteddigitalops.bmc.com/skyscraper#lobby"
              className={classNames(styles.button, styles.buttonLight)}
            >
              Explore
            </a>
          </div>
        </Section>
      </div>
      <div className={styles.solutions}>
        <Section>
          <h2 className={styles.solutionsHeading}>
            BMC solutions put AI to work across every aspect of your business.
          </h2>
          <ul className={styles.solutionsList}>
            <li className={styles.solution}>
              <Icon icon="deliver" className={styles.solutionIcon} />
              <h3 className={styles.solutionHeading}>
                Deliver smarter service
              </h3>
              <p className={styles.solutionText}>
                Discover how BMC’s automated, AI-driven service management
                (AISM) solutions enable faster, more accurate, and more
                efficient ways of delivering service innovations.
              </p>
              <a
                href="https://www.bmc.com/it-solutions/bmc-helix.html"
                target="_blank"
                rel="noreferrer"
                className={styles.solutionLink}
              >
                Explore
              </a>
            </li>
            <li className={styles.solution}>
              <Icon icon="reframe" className={styles.solutionIcon} />
              <h3 className={styles.solutionHeading}>Reframe the mainframe</h3>
              <p className={styles.solutionText}>
                Capitalize on change and make smarter, faster, and more
                confident decisions with BMC Automated Mainframe Intelligence
                (BMC AMI).
              </p>
              <a
                href="https://www.bmc.com/it-solutions/bmc-ami-automated-mainframe-intelligence.html"
                target="_blank"
                rel="noreferrer"
                className={styles.solutionLink}
              >
                Explore
              </a>
            </li>
            <li className={styles.solution}>
              <Icon icon="automate" className={styles.solutionIcon} />
              <h3 className={styles.solutionHeading}>
                Automate business workflows
              </h3>
              <p className={styles.solutionText}>
                Unlock advanced workflow orchestration capabilities from
                mainframe to cloud and deliver data-driven solutions faster with
                Control-M.
              </p>
              <a
                href="https://www.bmc.com/it-solutions/control-m.html"
                target="_blank"
                rel="noreferrer"
                className={styles.solutionLink}
              >
                Explore
              </a>
            </li>
          </ul>
        </Section>
      </div>
      <VideoQuoteCarousel />
      <div className={styles.contact} ref={contactContainer}>
        <div className={styles.contactBg} ref={contactBg}></div>
        <Section>
          <h2 className={styles.contactHeading}>
            Are you ready to make the most of every opportunity?
          </h2>

          <a
            href="https://www.bmc.com/forms/contact-bmc.html"
            target="_blank"
            rel="noreferrer"
            className={classNames(styles.button)}
          >
            Contact us
          </a>
        </Section>
      </div>
      <Footer />
    </>
  );
}

export default LandingPage;
