import SkyscraperPage from "./pages/Skyscraper/Skyscraper.js";
import LandingPage from "./pages/Landing/Landing.js";
import InternalPage from "./pages/Internal/Internal.js";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/skyscraper" element={<SkyscraperPage />} />
      <Route path="/internal" element={<InternalPage />} />
    </Routes>
  );
}

export default App;
