import styles from "./Footer.module.scss";

export const Footer = () => {
  return (
    <div className={styles.root}>
      &copy;Copyright 2005-2023 BMC Software, Inc. Use of this site signifies
      your acceptance of BMC's{" "}
      <strong>
        <a
          href="https://www.bmc.com/legal/terms-of-use.html"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>
      </strong>
      . BMC, the BMC logo, and other BMC marks are assets of BMC Software, Inc.
      These trademarks are{" "}
      <a
        href="https://www.bmc.com/legal/bmc-us-registered-trademarks.html"
        target="_blank"
        rel="noreferrer"
      >
        registered
      </a>{" "}
      and{" "}
      <a
        href="https://www.bmc.com/available/supported-product-az-list.html"
        target="_blank"
        rel="noreferrer"
      >
        may be registered
      </a>{" "}
      in the U.S. and in other countries.<span id="cookie-consent"></span>
    </div>
  );
};
