import { Cover } from "./components/Cover";
import { Introduction } from "./components/Introduction";
import { Material } from "./components/Material";
import { ProofPoint } from "./components/ProofPoint";
import { Solution } from "./components/Solution";

export const ScreenList = {
  cover: Cover,
  introduction: Introduction,
  material: Material,
  proofPoint: ProofPoint,
  solution: Solution,
};
