import { Header } from "../../components/Header/Header";
import { Skyscraper } from "../../components/Skyscraper/Skyscraper";
import { Lobby } from "../../components/Lobby/Lobby";
import { Control } from "../../components/Control/Control";
import { data } from "../../data";
import { SolutionsProvider } from "../../context/SolutionsContext";
import { useState, useRef, useEffect } from "react";
import { ScrollLockProvider } from "../../context/ScrollLockContext";
import { Analytics } from "@vercel/analytics/react";
import { CookiesProvider } from "react-cookie";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styles from "./Skyscraper.module.scss";

function SkyscraperPage() {
  const [activeSection, setActiveSection] = useState("intro");
  const [activeProduct, setActiveProduct] = useState(null);
  const [scrollDirection, setScrollDirection] = useState(-1);
  const containerRef = useRef();

  useEffect(() => {
    if (window.location.hash === "#lobby")
    gsap.to(window, {
      scrollTo: "#lobby",
      duration: 0,
    });

    const ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: containerRef.current,
        start: "top top",
        end: "bottom bottom",
        onUpdate: (self) => setScrollDirection(self.direction),
        // markers: true,
      });
    }, containerRef);

    return () => ctx.revert();
  }, []);

  return (
    <>
      <CookiesProvider>
        <ScrollLockProvider>
          <SolutionsProvider>
            <div className={styles.root} ref={containerRef}>
              <Header
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                scrollDirection={scrollDirection}
              />
              <Skyscraper
                {...data.skyscraper}
                setActiveSection={setActiveSection}
              />
              <Lobby
                {...data.lobby}
                isActive={activeSection === "lobby"}
                setActiveSection={setActiveSection}
                activeProduct={activeProduct}
                setActiveProduct={setActiveProduct}
              />
              <Control
                activeSection={activeSection}
                setActiveProduct={setActiveProduct}
              />
            </div>
          </SolutionsProvider>
        </ScrollLockProvider>
        <Analytics />
      </CookiesProvider>
    </>
  );
}

export default SkyscraperPage;
