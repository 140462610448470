export const IconCostChange = ({ className }) => (
  <svg
    className={className}
    width="46"
    height="54"
    viewBox="0 0 46 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.6883 25.4129V9.86039H30.1886L22.656 1.30884L15.1235 9.86039H18.6238V25.4129H26.6883Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5193 27.5928L9.72993 16.8034L12.1582 14.3752L1 13.6682L1.70695 24.8264L4.13521 22.3981L14.9246 33.1876L20.5193 27.5928Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.4807 27.5928L36.2701 16.8034L33.8419 14.3752L45.0001 13.6682L44.2931 24.8264L41.8648 22.3981L31.0754 33.1876L25.4807 27.5928Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 52.6177C31.8366 52.6177 39 45.4542 39 36.6177C39 27.7811 31.8366 20.6177 23 20.6177C14.1634 20.6177 7 27.7811 7 36.6177C7 45.4542 14.1634 52.6177 23 52.6177Z"
      fill="#261E44"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.688 31.8636C27.5006 30.7558 26.927 29.7501 26.0687 29.0249C25.2105 28.2997 24.1232 27.902 22.9996 27.9021C20.3741 27.9021 18.2454 29.6338 18.2454 32.26C18.2454 34.8861 20.6225 36.0753 22.9996 36.6185C25.3768 37.1533 27.7539 38.352 27.7539 40.977C27.7539 43.6019 25.6252 45.3349 22.9996 45.3349C21.7387 45.3349 20.5295 44.834 19.6379 43.9424C18.7463 43.0508 18.2454 41.8415 18.2454 40.5806"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 48.5059V45.3354M23 27.9032V24.7327V27.9032Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
