import styles from "./Material.module.scss";

export const Material = ({
  productName,
  heading,
  text,
  pdfHeading,
  pdfText,
  pdfFile,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <div className={styles.head}>
            <span
              className={styles.headTitle}
            >{`// Supporting Materials`}</span>
            <span
              className={styles.badge}
              dangerouslySetInnerHTML={{ __html: productName }}
            />
          </div>
          <div className={styles.content}>
            {heading && (
              <h2
                className={styles.heading}
                dangerouslySetInnerHTML={{ __html: heading }}
              />
            )}
            {text && (
              <p
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}
          </div>
        </div>
        <div className={styles.aside}>
          <div className={styles.head}>
            <span className={styles.headTitle}>Learn</span>
            <span className={styles.badge}>PDF</span>
          </div>
          <div className={styles.content}>
            {pdfHeading && <h2 className={styles.pdfHeading}>{pdfHeading}</h2>}
            {pdfText && <p className={styles.pdfText}>{pdfText}</p>}
            {pdfFile && (
              <a
                className={styles.pdfLink}
                href={pdfFile}
                target="_blank"
                rel="noreferrer"
              >
                Download PDF
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.75"
                    y="0.75"
                    width="23.5"
                    height="23.5"
                    rx="3.25"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M6 19.4453H19.5934"
                    stroke="#0F072C"
                    strokeWidth="1.8"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.36328 11.1127L12.7502 15.4996M12.7502 15.4996L17.1371 11.1127M12.7502 15.4996V4.86133"
                    stroke="#0F072C"
                    strokeWidth="1.8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
