export const IconReframe = ({ className }) => (
  <svg
    className={className}
    width="51"
    height="42"
    viewBox="0 0 51 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8461 26.9968H7.35333"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.15887 28.6176V26.9968"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.47333 31.0162C5.47333 30.0438 6.25129 29.2659 7.22374 29.2659C8.19619 29.2659 8.97412 30.0438 8.97412 31.0162C8.97412 31.9887 8.19619 32.7666 7.22374 32.7666C6.31612 32.7666 5.47333 31.9887 5.47333 31.0162Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7813 18.8933H5.01953"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8461 10.5947H11.2432"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 19.0875C1 18.1151 1.77797 17.3372 2.75041 17.3372C3.72286 17.3372 4.50083 18.1151 4.50083 19.0875C4.50083 20.06 3.72286 20.8381 2.75041 20.8381C1.77797 20.8381 1 20.06 1 19.0875Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.48297 10.5949C7.48297 9.62241 8.26094 8.84448 9.23339 8.84448C10.2058 8.84448 10.9838 9.62241 10.9838 10.5949C10.9838 11.5673 10.2058 12.3452 9.23339 12.3452C8.26094 12.4101 7.48297 11.5673 7.48297 10.5949Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.8137 11.0486H41.1946"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.3891 5.60278V11.0484"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.8785 18.6338H46.0568"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.0098 2.68548C43.334 3.5931 42.8154 4.63038 41.9077 4.95453C41.0001 5.27868 39.9629 4.75991 39.6387 3.8523C39.3146 2.94468 39.8332 1.9074 40.7408 1.58325C41.6484 1.2591 42.6857 1.71304 43.0098 2.68548Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.0763 18.4391C50.0763 19.4115 49.2983 20.1896 48.3259 20.1896C47.3534 20.1896 46.5754 19.4115 46.5754 18.4391C46.5754 17.4666 47.3534 16.6887 48.3259 16.6887C49.2335 16.6887 50.0763 17.4666 50.0763 18.4391Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.073 27.0618H40.2869"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.1119 27.0617C44.1119 28.0341 43.3339 28.812 42.3615 28.812C41.389 28.812 40.6111 28.0341 40.6111 27.0617C40.6111 26.0892 41.389 25.3113 42.3615 25.3113C43.2691 25.3113 44.1119 26.0892 44.1119 27.0617Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.3501 38.2772H18.5041C17.2723 38.2772 16.2999 37.3048 16.2999 36.073V3.20415C16.2999 1.97238 17.2723 1 18.5041 1H33.3501C34.5819 1 35.5543 1.97238 35.5543 3.20415V36.073C35.6191 37.3048 34.5819 38.2772 33.3501 38.2772Z"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.5251 1.71313L21.4863 4.88973L16.4295 16.9482"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.7197 11.5671L22.5236 22.1992L16.4944 26.0242"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4944 36.9158L25.2464 33.415L29.7197 27.71"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.402 38.2124V40.0276C17.402 40.6111 17.8558 41.0001 18.3745 41.0001H33.6095C34.193 41.0001 34.582 40.5463 34.582 40.0276V37.9531"
      stroke="currentColor"
      strokeMiterlimit="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.8493 38.2123V1"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.5722 38.2123V6.83472"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.2853 3.7228C33.2853 4.11178 32.9612 4.43599 32.5722 4.43599C32.1832 4.43599 31.8591 4.11178 31.8591 3.7228C31.8591 3.33382 32.1832 3.00977 32.5722 3.00977C32.9612 3.00977 33.2853 3.33382 33.2853 3.7228Z"
      fill="#424143"
    />
  </svg>
);
