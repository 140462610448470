import React, { useState } from "react";
import cx from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { VideoQuoteCard } from "../VideoQuoteCard/VideoQuoteCard";
import { Section } from "../Section/Section";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styles from "./VideoQuoteCarousel.module.scss";

gsap.registerPlugin(ScrollTrigger);

export const VideoQuoteCarousel = ({ children, className }) => {
  const [swiper, setSwiper] = useState();
  const [activeIndex, setActiveIndex] = useState(0);

  const onSwiper = (instance) => {
    setSwiper(instance);
  };

  const onSlideChange = (instance) => {
    setActiveIndex(instance.realIndex);
  };

  const onPreviousSlide = () => {
    if (swiper) {
      if (swiper.isBeginning) {
        swiper.slideTo(swiper.slides.length - 1);
      } else {
        swiper.slidePrev();
      }
    }
  };

  const onNextSlide = () => {
    if (swiper) {
      if (swiper.isEnd) {
        swiper.slideTo(0);
      } else {
        swiper.slideNext();
      }
    }
  };

  const items = [
    {
      videoId: "AvLFg0HqrbY",
      posterSrc: "/landing/QuoteDeepak.jpg",
      testimonial:
        "BMC has been a pioneer in AIOps working very closely on the principles of AIOps and building the principles into our BMC Helix platform.",
      name: "Deepak Singla",
      position: "Country Director, India",
    },
    {
      videoId: "haWMmECMofA",
      posterSrc: "/landing/QuoteKaren.jpg",
      testimonial:
        "If we look in that isolation of just mainframe or just public cloud or private cloud, then we're missing that higher level, that executive view.",
      name: "Karen Hughes",
      position: "Field Sales Manager",
    },
    {
      videoId: "abpltuJzw5Y",
      posterSrc: "/landing/QuoteKatie.jpg",
      testimonial:
        "We're the only one with the continuous optimization to ensure that our customers have the capacity they need.",
      name: "Katie Tierney",
      position: "AVP, Digital Service and Operations Management Americas",
    },
    {
      videoId: "aHcMHfZan-M",
      posterSrc: "/landing/QuoteTim.jpg",
      testimonial:
        "One of the most difficult things in managing your data projects is actually orchestrating your data pipelines.",
      name: "Tim Eusterman",
      position: "AVP, Solution Marketing Management",
    },
  ];

  return (
    <div className={cx(className, styles.root)}>
      <Section>
        <Swiper
          className={styles.slider}
          modules={[Pagination]}
          slidesPerView={1}
          spaceBetween={20}
          loop={false}
          breakpoints={{
            1024: {
              slidesPerView: 1.2,
            },
          }}
          onSlideChange={onSlideChange}
          onSwiper={onSwiper}
          preventClicks={false}
          simulateTouch={false}
        >
          {items?.map((item, index) => (
            <SwiperSlide key={index} className={styles.slide}>
              <VideoQuoteCard
                key={index}
                videoId={item?.videoId}
                posterSrc={item?.posterSrc}
                testimonial={item?.testimonial}
                name={item?.name}
                position={item?.position}
                active={index === activeIndex}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={styles.buttons}>
          <button
            className={cx(styles.button, styles.prev)}
            onClick={onPreviousSlide}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <button
            className={cx(styles.button, styles.next)}
            onClick={onNextSlide}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </Section>
    </div>
  );
};
