export const IconDataOps = ({ className }) => (
  <svg
    className={className}
    width="42"
    height="41"
    viewBox="0 0 42 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.1225 9.90763C34.1225 9.90763 34.1 9.90763 34.0887 9.90763C34.1056 9.69394 34.1225 9.47462 34.1225 9.25531C34.1225 5.27949 30.8946 2.0516 26.9187 2.0516C25.3779 2.0516 23.9495 2.54084 22.7798 3.36187C21.0984 1.89414 18.8996 1 16.4928 1C11.6228 1 7.60199 4.64966 7.01153 9.36215C3.59806 9.93013 1 12.8881 1 16.459C1 20.4348 4.22789 23.6627 8.20371 23.6627H34.1225C37.9183 23.6627 41 20.5811 41 16.7852C41 12.9893 37.9183 9.90763 34.1225 9.90763Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.553 27.5593H4.30091V31.6975"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="2.34787"
      cy="2.34787"
      r="1.59787"
      transform="matrix(0 -1 -1 0 6.64917 36.2837)"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.4495 27.5593H37.7015V31.6975"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="37.7011"
      cy="33.9358"
      r="1.59787"
      transform="rotate(-90 37.7011 33.9358)"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.4855 38.3271C22.4855 39.2079 21.7685 39.925 20.8877 39.925C20.0069 39.925 19.2898 39.2079 19.2898 38.3271C19.2898 37.4463 20.0069 36.7292 20.8877 36.7292C21.7685 36.7292 22.4855 37.4463 22.4855 38.3271Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.8879 36.3904L20.8879 31.02"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.14282 38.6689H18.8759"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.9006 38.6689H33.6337"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4285 11.4261V28.5752C12.4285 30.1558 16.2663 31.4299 20.9999 31.4299C25.7335 31.4299 29.5713 30.1488 29.5713 28.5752V11.4261"
      fill="#30294D"
    />
    <path
      d="M12.4285 11.4261V28.5752C12.4285 30.1558 16.2663 31.4299 20.9999 31.4299C25.7335 31.4299 29.5713 30.1488 29.5713 28.5752V11.4261"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9999 14.2808C25.7338 14.2808 29.5713 13.0027 29.5713 11.4261C29.5713 9.8495 25.7338 8.57141 20.9999 8.57141C16.266 8.57141 12.4285 9.8495 12.4285 11.4261C12.4285 13.0027 16.266 14.2808 20.9999 14.2808Z"
      fill="#30294D"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.5713 17.1427C29.5713 18.7232 25.7335 19.9974 20.9999 19.9974C16.2663 19.9974 12.4285 18.7163 12.4285 17.1427"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.5713 22.8593C29.5713 24.4398 25.7335 25.714 20.9999 25.714C16.2663 25.714 12.4285 24.4328 12.4285 22.8593"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
