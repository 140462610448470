import styles from "./ProofPoint.module.scss";
import { Icon } from "../../../Icon";

export const ProofPoint = ({ productName, text }) => {
  if (!text) return null;

  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <span className={styles.headTitle}>{`// Proof in action`}</span>
        <span
          className={styles.badge}
          dangerouslySetInnerHTML={{ __html: productName }}
        />
      </div>
      <div className={styles.content}>
        <Icon icon="circleCheckmark" className={styles.icon} />
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};
