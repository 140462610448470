export const IconPerformance = ({ className }) => (
  <svg
    className={className}
    width="54"
    height="32"
    viewBox="0 0 54 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8333 27.604H1C1 13.2446 12.6406 1.604 27 1.604C41.3594 1.604 53 13.2446 53 27.604H42.1667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27 6.9673V1.604M45.9585 17.4638L49.75 15.2747M16.0545 9.45078L13.8654 5.65926M4.25 15.2747L8.04152 17.4638M37.9455 9.45078L40.1346 5.65926"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9706 18.0283C15.8824 17.936 15.824 17.8194 15.803 17.6936C15.7871 17.5809 15.801 17.466 15.8432 17.3603C15.8855 17.2546 15.9546 17.1618 16.0438 17.0911C16.133 17.0205 16.2392 16.9744 16.3517 16.9574C16.4643 16.9405 16.5793 16.9533 16.6853 16.9946L29.8648 22.9272C30.3906 23.1682 30.8565 23.5227 31.2288 23.9653C31.6012 24.4079 31.8708 24.9276 32.0182 25.4869C32.1656 26.0462 32.1872 26.6313 32.0814 27.1999C31.9756 27.7686 31.745 28.3067 31.4062 28.7755C31.0675 29.2443 30.629 29.6321 30.1223 29.9111C29.6157 30.1901 29.0535 30.3532 28.4762 30.3888C27.8989 30.4244 27.3209 30.3315 26.7838 30.1169C26.2468 29.9022 25.764 29.5711 25.3702 29.1475L15.9706 18.0283Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
