import styles from "./OpsIntro.module.scss";
import { useEffect, useRef } from "react";
import lottie from "lottie-web/build/player/lottie.min.js";
import lottieData from "../../connectedops.json";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollToPlugin);

export const OpsIntro = ({ isActive, productRefs, setInViewProduct }) => {
  const lottieContainer = useRef(null);
  const lottieRef = useRef(null);
  const rootRef = useRef(null);

  useEffect(() => {
    lottieRef.current = lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: lottieData,
      name: "intro",
    });

    lottieRef.current.addEventListener("complete", () => {
      const idLists = [
        ["#lottie-title-conn"],
        ["#lottie-title-service", "#lottie-icon-service"],
        ["#lottie-title-ai", "#lottie-icon-ai"],
        ["#lottie-title-dev", "#lottie-icon-dev"],
        ["#lottie-title-data", "#lottie-icon-data"],
        ["#lottie-title-auto", "#lottie-icon-auto"],
      ];
      idLists.forEach((idList, index) => {
        idList.forEach((id) => {
          lottieContainer.current
            .querySelector(id)
            .addEventListener("click", () =>
              gsap.to(window, { scrollTo: productRefs.current[index] })
            );
        });
      });
    });

    return () => {
      lottie.destroy("intro");
    };
  }, []);

  useEffect(() => {
    if (isActive) {
      lottieRef.current.play();
    }
  }, [isActive]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: rootRef.current,
        start: "-50% top",
        end: "50% top",
        onEnter: () => setInViewProduct(null),
        onEnterBack: () => setInViewProduct(null),
        // markers: true
      });
    });

    return () => ctx.revert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInViewProduct]);

  return (
    <div
      className={styles.root}
      // onClick={() => gsap.to(window, { scrollTo: productRefs.current[0] })}
      ref={rootRef}
    >
      <div className={styles.lottieContainer} ref={lottieContainer} />
    </div>
  );
};
