export const IconAtom = ({ className }) => (
  <svg
    className={className}
    width="46"
    height="52"
    viewBox="0 0 46 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.8584 29.1114C24.5896 29.1114 25.993 27.708 25.993 25.9768C25.993 24.2457 24.5896 22.8423 22.8584 22.8423C21.1273 22.8423 19.7239 24.2457 19.7239 25.9768C19.7239 27.708 21.1273 29.1114 22.8584 29.1114Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M22.9997 51.2379C28.2248 51.2379 32.4605 39.9917 32.4605 26.1189C32.4605 12.2461 28.2248 1 22.9997 1C17.7746 1 13.5388 12.2461 13.5388 26.1189C13.5388 39.9917 17.7746 51.2379 22.9997 51.2379Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M44.4806 38.6803C47.0931 34.0972 39.5948 24.7589 27.7327 17.8225C15.8705 10.8861 4.13645 8.97831 1.5239 13.5614C-1.08865 18.1444 6.40964 27.4828 18.2718 34.4192C30.134 41.3556 41.868 43.2634 44.4806 38.6803Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M27.7282 34.4197C39.5904 27.4833 47.0886 18.1449 44.4761 13.5618C41.8636 8.97876 30.1295 10.8865 18.2673 17.8229C6.40517 24.7593 -1.09312 34.0977 1.51942 38.6808C4.13197 43.2638 15.866 41.3561 27.7282 34.4197Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);
