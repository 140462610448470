import React, { useRef, useState, useEffect } from "react";
import cn from "classnames";
import styles from "./ProgressBar.module.scss"; // Import the CSS file with styles

export const ProgressBar = ({
  className,
  numSteps,
  progress,
  onPrev,
  onNext,
  goToScreen,
}) => {
  const progressRef = useRef(null); // Create a ref for the progress bar element
  const prevRef = useRef(null); // Create a ref for the "Prev" button element
  const nextRef = useRef(null); // Create a ref for the "Next" button element
  const circlesRef = useRef([]); // Create a ref for the circle elements
  const [currentActive, setCurrentActive] = useState(1); // Define state for current active step

  useEffect(() => {
    const updateProgress = () => {
      // Update progress bar width and button disabled state based on current active step
      const progressPercentage = ((currentActive - 1) / (numSteps - 1)) * 100;
      progressRef.current.style.width = `${progressPercentage}%`;
      prevRef.current.disabled = currentActive === 1;
      nextRef.current.disabled = currentActive === numSteps;
    };
    updateProgress(); // Call updateProgress function when current active step or number of steps changes
  }, [currentActive, numSteps]);

  useEffect(() => {
    setCurrentActive(progress);
  }, [progress]);

  return (
    <div className={cn(className, styles.container)}>
      <div className={styles.progressContainer}>
        <div className={styles.progress} ref={progressRef}></div>
        {Array.from({ length: numSteps }, (_, i) => (
          <span
            className={cn(styles.circle, {
              [styles.active]: i + 1 === currentActive,
              [styles.complete]: i < currentActive,
            })}
            key={i + 1}
            ref={(el) => (circlesRef.current[i] = el)} // Store circle elements in the ref array
            onClick={() => {
              goToScreen(i + 1);
            }}
          ></span>
        ))}
      </div>
      <button
        className={styles.btn}
        disabled={currentActive === 1}
        onClick={onPrev}
        ref={prevRef} // Attach "Prev" button ref to the prevRef
      >
        Prev
      </button>
      <button
        className={styles.btn}
        disabled={currentActive === numSteps}
        onClick={onNext}
        ref={nextRef} // Attach "Next" button ref to the nextRef
      >
        Next
      </button>
    </div>
  );
};
