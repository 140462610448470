export const IconAutomate = ({ className }) => (
  <svg
    className={className}
    width="45"
    height="42"
    viewBox="0 0 45 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.046 15.0803L23.2988 9.79297L32.5517 15.0803V25.77L23.2988 31.1148L14.046 25.77V15.0803Z"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M18.1839 17.4944L23.2988 14.5059L28.4138 17.4944"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.0575 20.5403L42.6667 22.1495"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.3908 20.5403L42.7241 22.2069"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.6897 34.2757C29.1035 35.9999 26.0575 37.0344 22.7242 37.0344C14.8506 37.0344 8.35636 31.4022 6.97705 23.9309"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6207 7.14949C16.977 5.77018 19.7931 4.96558 22.7241 4.96558C30.1954 4.96558 36.4597 10.0805 38.2413 16.9771"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.6551 36.2299C32.1494 39.2184 27.6666 41 22.7241 41C11.6896 41 2.72412 32.0345 2.72412 21"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.5402 29.9656C40.023 31.0001 39.3908 31.9771 38.7012 32.9541"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.58618 6.86208C12.2069 3.24139 17.2069 1 22.7241 1C32.3793 1 40.4253 7.83909 42.3218 16.977C42.6092 18.2989 42.7241 19.6207 42.7241 21"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.27587 20.8278L2.60919 19.2761"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 21.0003L2.55173 19.2761"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
