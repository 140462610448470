export const IconDownload = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="4" fill="currentColor" />
    <path
      d="M4 15.5586H16"
      stroke="#150A3F"
      strokeWidth="1.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 8.88391L10.0095 12.3934M10.0095 12.3934L13.5191 8.88391M10.0095 12.3934V3.88281"
      stroke="#150A3F"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
