import styles from "./Intro.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { useCookies } from "react-cookie";
import poster from "../../images/intro-poster.jpg";
import { useScrollLock } from "../../context/ScrollLockContext";
import classNames from "classnames";
import mobileIntro from "../../images/intro-mobile.mp4"
import { useWindowWidth } from "@react-hook/window-size";

export const Intro = ({
  isActive,
  setActiveSection,
  buildingTranslate,
  isPaused,
  isFinished,
  setIsFinished,
}) => {
  const rootRef = useRef();
  const videoRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const { addScrollLock, removeScrollLock } = useScrollLock();
  const windowWidth = useWindowWidth();
  const [, setCookie] = useCookies(["skipIntro"]);
  const oneHourFromNow = new Date();
  oneHourFromNow.setTime(oneHourFromNow.getTime() + 60 * 60 * 1000); // 60 minutes * 60 seconds * 1000 milliseconds = 1 hour

  const fadeOut = useCallback(() => {
    gsap.to(videoRef.current, {
      opacity: 0,
      duration: 1.5,
      ease: "power3.inOut",
    });
    gsap.to(rootRef.current, {
      y: "-100vh",
      duration: 1,
      delay: 1,
      ease: "power3.out",
      onComplete: () => setActiveSection("skyscraper"),
    });
    buildingTranslate();
  }, [setActiveSection, buildingTranslate]);

  const timeUpdateListener = useCallback(
    (e) => {
      if (e.target.currentTime > 13.5) {
        e.currentTarget.removeEventListener("timeupdate", timeUpdateListener);
        fadeOut();
        setTimeout(() => {
          setCookie("skipIntro", "active", {
            path: "/",
            expires: oneHourFromNow,
          });
        }, 2000);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fadeOut]
  );

  useEffect(() => {
    if (isFinished) {
      fadeOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  useEffect(() => {
    if (isActive) {
      addScrollLock("intro");
      videoRef.current.play();
      gsap.to(videoRef.current, {
        opacity: 1,
        duration: 0,
      });
      gsap.to(rootRef.current, {
        y: 0,
        duration: 0,
      });
      videoRef.current.addEventListener("timeupdate", timeUpdateListener);
      setIsFinished(false);
    } else {
      removeScrollLock("intro");
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      gsap.to(videoRef.current, {
        opacity: 0,
        duration: 0,
      });
      gsap.to(rootRef.current, {
        y: "-100vh",
        duration: 0,
      });
      videoRef.current.removeEventListener("timeupdate", timeUpdateListener);
    }

    return () => {
      removeScrollLock("intro");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, addScrollLock, removeScrollLock, timeUpdateListener]);

  useEffect(() => {
    if (isPaused) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  }, [isPaused]);

  return (
    <div className={styles.root} ref={rootRef} id="intro">
      <video
        src={windowWidth > 1024 ? "https://www.bmc.com/content/dam/bmc/campaign/connecteddigitalops/BMC_23003_CloudBank_TR_3_doNotTrack.mp4" : mobileIntro}
        className={styles.introVideo}
        autoPlay
        muted
        playsInline
        poster={poster}
        onWaiting={() => setIsLoading(true)}
        onPlaying={() => setIsLoading(false)}
        ref={videoRef}
      />
      <div
        className={classNames(styles.spinner, { [styles.active]: isLoading })}
      >
        <div className={styles.spinnerRing}>
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};
