import React, { createContext, useContext } from "react";

const Context = createContext({
  currentSolutionsModal: null,
  setCurrentSolutionsModal: () => null,
  currentSolutionsTab: null,
  setCurrentSolutionsTab: () => null,
});

const SolutionsProvider = ({ children }) => {
  const [currentSolutionsModal, setCurrentSolutionsModal] =
    React.useState(false);
  const [currentSolutionsTab, setCurrentSolutionsTab] =
    React.useState(false);

  return (
    <Context.Provider
      value={{
        currentSolutionsModal,
        setCurrentSolutionsModal,
        currentSolutionsTab,
        setCurrentSolutionsTab
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useSolutionsModal = () => useContext(Context);

export { SolutionsProvider, useSolutionsModal };
