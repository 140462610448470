export const IconOperational = ({ className }) => (
  <svg
    className={className}
    width="44"
    height="22"
    viewBox="0 0 44 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.45656 17.9718C6.45656 19.2755 5.39874 20.3333 4.0951 20.3333C2.79146 20.3333 1.73364 19.2755 1.73364 17.9718C1.73364 16.6682 2.79146 15.6104 4.0951 15.6104C5.39874 15.6104 6.45656 16.6682 6.45656 17.9718Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3982 9.5846C18.3982 10.8882 17.3404 11.9461 16.0368 11.9461C14.7331 11.9461 13.6753 10.8882 13.6753 9.5846C13.6753 8.28096 14.7331 7.22314 16.0368 7.22314C17.3404 7.22314 18.3982 8.28096 18.3982 9.5846Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.332 13.7638C30.332 15.0674 29.2742 16.1253 27.9706 16.1253C26.6669 16.1253 25.6091 15.0674 25.6091 13.7638C25.6091 12.4602 26.6669 11.4023 27.9706 11.4023C29.2742 11.4023 30.332 12.4602 30.332 13.7638Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.1992 2.79066C42.5121 4.05705 41.7448 5.33835 40.4784 5.65867C39.212 5.979 37.9307 5.20426 37.6104 3.93786C37.2975 2.67147 38.0648 1.39017 39.3312 1.06985C40.5976 0.756972 41.8789 1.52426 42.1992 2.79066Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.7439 12.214L38.1245 4.91357"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2642 10.3599L25.7434 12.982"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.03198 16.6174L14.0997 10.9409"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
