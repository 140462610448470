import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faPause } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import styles from "./PlayAudio.module.scss";
import { formatTime } from "../../utils/helpers";

export const PlayAudio = ({
  isPlaying,
  setIsPlaying,
  percentage,
  currentTime,
  totalDuration,
  onSliderChange,
}) => {
  const currentTimeFormatted = formatTime(currentTime);
  const totalDurationFormatted = formatTime(totalDuration);

  const handleSliderChange = (event) => {
    const newPercentage = event.target.value;
    percentage = event.target.value;
    onSliderChange(newPercentage);
  };

  const sliderStyles = {
    webkitAppearance: "none",
    width: "100%",
    height: "3px",
    background:
      "linear-gradient(to right, var(--color-primary), var(--color-primary) " +
      percentage +
      "%, var(--color-light) " +
      percentage +
      "%, var(--color-light))",
  };

  return (
    <div className={classNames(styles.root, { [styles.active]: isPlaying })}>
      <button
        className={styles.audioButton}
        onClick={(e) => {
          e.preventDefault();
          setIsPlaying((currentState) => !currentState);
        }}
      >
        <div className={styles.audioIcon}>
          {isPlaying ? (
            <div className={styles.pauseIcon}>
              <FontAwesomeIcon icon={faPause} className={styles.pauseBars} />
              <svg className={styles.percent}>
                <circle cx="23" cy="23" r="21"></circle>
                <circle
                  cx="23"
                  cy="23"
                  r="21"
                  style={{ "--percent": percentage }}
                ></circle>
              </svg>
            </div>
          ) : (
            <div className={styles.play}>
              <FontAwesomeIcon
                icon={faPlayCircle}
                className={styles.playIcon}
              />
              <span>Play Audio</span>
            </div>
          )}
        </div>
      </button>
      <span className={styles.audio}>
        {isPlaying && (
          <span className={styles.progress}>
            <input
              type="range"
              min="0"
              max="100"
              value={percentage}
              onChange={handleSliderChange}
              style={sliderStyles}
              className={styles.slider}
            />
            <span className={styles.time}>
              <span>{currentTimeFormatted}</span>
              <span>{totalDurationFormatted}</span>
            </span>
          </span>
        )}
      </span>
    </div>
  );
};
