/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import YouTube from "react-youtube";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import styles from "./VideoQuoteCard.module.scss";

export const VideoQuoteCard = ({
  className,
  videoId,
  posterSrc,
  testimonial,
  name,
  position,
  active = false,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [video, setVideo] = useState();
  const [ready, setIsReady] = useState(false);

  const classList = classnames(styles.root, className, {
    [styles.isPlaying]: isPlaying,
  });

  const videoOptions = {
    height: "480",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      controls: 1,
      playsinline: 1,
      enablejsapi: 1,
      start: 1,
      rel: 0,
    },
  };

  const onPlay = () => {
    setIsPlaying(true);
  };

  const onEnd = () => {
    setIsPlaying(false);
  };

  const onVideoReady = (event) => {
    // access to player in all event handlers via event.target
    setVideo(event.target);
  };

  useEffect(() => {
    // Check if video instance exists and is fully loaded
    if (!video?.h) {
      return;
    }

    if (!active) {
      video.pauseVideo();
      setIsPlaying(false);
    }
  }, [active, video]);

  useEffect(() => {
    // delay youtube loading to improve performance
    setTimeout(() => {
      setIsReady(true);
    }, 4000);
  }, []);

  return (
    <div className={classList}>
      {ready && (
        <YouTube
          videoId={videoId}
          iframeClassName={styles.video}
          opts={videoOptions}
          onReady={(e) => onVideoReady(e)}
          onPlay={onPlay}
          onEnd={onEnd}
        />
      )}
      {posterSrc && (
        <div
          className={styles.posterContainer}
          style={{ backgroundImage: `url(${posterSrc})` }}
        />
      )}
      <div className={styles.testimonialContainer}>
        <span className={styles.textContainer}>
          <span className={styles.quote}>
            <svg
              width="40"
              height="32"
              viewBox="0 0 40 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7361 3.8147e-06L15.6364 4.4135C11.2649 7.4399 8.65887 11.2649 7.81821 15.8886C8.57481 15.5523 9.45751 15.3842 10.4663 15.3842C12.8202 15.3842 14.7537 16.1408 16.2669 17.654C17.7801 19.1672 18.5367 21.1007 18.5367 23.4546C18.5367 25.8925 17.7381 27.8261 16.1408 29.2552C14.5435 30.7684 12.5259 31.525 10.088 31.525C7.14568 31.525 4.74977 30.5583 2.9003 28.6247C0.966766 26.6912 0 23.917 0 20.3021C0 16.4351 1.13491 12.61 3.40471 8.82701C5.59044 5.12807 8.70091 2.18574 12.7361 3.8147e-06ZM34.1994 3.8147e-06L37.0997 4.4135C32.7282 7.4399 30.1222 11.2649 29.2815 15.8886C30.0381 15.5523 30.9208 15.3842 31.9296 15.3842C34.2835 15.3842 36.217 16.1408 37.7302 17.654C39.2434 19.1672 40 21.1007 40 23.4546C40 25.8925 39.2014 27.8261 37.6041 29.2552C36.0068 30.7684 33.9892 31.525 31.5513 31.525C28.609 31.525 26.2131 30.5583 24.3636 28.6247C22.4301 26.6912 21.4633 23.917 21.4633 20.3021C21.4633 16.4351 22.5982 12.61 24.868 8.82701C27.0537 5.12807 30.1642 2.18574 34.1994 3.8147e-06Z"
                fill="var(--color-primary)"
              />
            </svg>
          </span>
          {testimonial && <h3 className={styles.testimonial}>{testimonial}</h3>}
          {(name || position) && (
            <div className={styles.meta}>
              {name && <p className={styles.name}>{name}</p>}
              {position && <p className={styles.position}>{position}</p>}
            </div>
          )}
        </span>
        <button
          className={classnames(styles.playButtonWrapper, {
            [styles.inactive]: !active,
          })}
          onClick={(e) => {
            e.preventDefault();
            video?.playVideo();
          }}
        >
          <FontAwesomeIcon
            icon={faPlayCircle}
            className={classnames(styles.button, styles.playIconIcon)}
          />
          Play Video
        </button>
      </div>
    </div>
  );
};
