import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styles from './Title.module.scss'

gsap.registerPlugin(ScrollTrigger);

export const Title = ({ smallHeading, largeHeading }) => {
	const container = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.set(container.current, { opacity: 0 });

      gsap.fromTo(
        container.current,
        {
          opacity: 0,
          immediateRender: false,
        },
        {
          opacity: 1,
          immediateRender: false,
          scrollTrigger: {
            trigger: container.current,
            start: "top 50%",
            end: "top 10%",
            scrub: true,
            // markers: true,
          },
        }
      );

      gsap.fromTo(
        container.current,
        {
          opacity: 1,
          immediateRender: false,
        },
        {
          opacity: 0,
          immediateRender: false,
          scrollTrigger: {
            trigger: container.current,
            start: "bottom 90%",
            end: "bottom 60%",
            scrub: true,
            // markers: true,
          },
        }
      );
    }, container);

    return () => ctx.revert();
  }, []);

	return <div className={styles.root} ref={container}>
		<h1 className={styles.heading}>
			<span className={styles.smallHeading}>{smallHeading}</span>
			{largeHeading}
		</h1>
	</div>
}