import { ScreenList } from "./ScreenList";

export const Screen = ({ productName, screen, ...props }) => {
  // Check if screen exists
  if (screen !== null) {
    // Get the type to use it in Screen List Component
    const DynamicComponent = ScreenList[screen?.type];
    if (DynamicComponent !== undefined) {
      return (
        <DynamicComponent
          productName={productName}
          {...screen?.data}
          {...props}
        />
      );
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
};
