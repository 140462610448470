export const IconDevOps = ({ className }) => (
  <svg
    className={className}
    width="42"
    height="40"
    viewBox="0 0 42 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7729 23.6183V30.5334H6.05237"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="4.06415"
      cy="30.5344"
      r="1.48603"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.1427 23.6183V30.5334H35.8632"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="2.23603"
      cy="2.23603"
      r="1.48603"
      transform="matrix(-1 0 0 1 40.0872 28.2983)"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="26.9221"
      cy="37.5295"
      r="1.48603"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.9226 23.6183L26.9226 35.2953"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="14.9953"
      cy="37.5295"
      r="1.48603"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9958 23.6183L14.9958 35.2953"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="20.957"
      cy="33.8049"
      r="1.48603"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9574 23.6183L20.9574 31.5686"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.7892 9.90763C33.7892 9.90763 33.7667 9.90763 33.7555 9.90763C33.7723 9.69394 33.7892 9.47462 33.7892 9.25531C33.7892 5.27949 30.5613 2.0516 26.5855 2.0516C25.0446 2.0516 23.6163 2.54084 22.4466 3.36187C20.7652 1.89414 18.5664 1 16.1595 1C11.2896 1 7.26874 4.64966 6.67827 9.36215C3.26481 9.93013 0.666748 12.8881 0.666748 16.459C0.666748 20.4348 3.89464 23.6627 7.87046 23.6627H33.7892C37.5851 23.6627 40.6667 20.5811 40.6667 16.7852C40.6667 12.9893 37.5851 9.90763 33.7892 9.90763Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.4484 10.5615L28.4919 14.0397L25.4484 17.518M15.8832 10.5615L12.8397 14.0397L15.8832 17.518M21.9702 9.69189L18.4919 18.3875"
      stroke="#30294D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
