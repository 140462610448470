import { useEffect, useState } from "react";
import classNames from "classnames";
import { Logo } from "../Icons/Logo";
import { Hamburger } from "../Hamburger/Hamburger";
import styles from "./Header.module.scss";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useScrollLock } from "../../context/ScrollLockContext";

gsap.registerPlugin(ScrollToPlugin);

export const Header = ({
  setActiveSection,
  activeSection,
  scrollDirection,
}) => {
  const [isActive, setIsActive] = useState(false);
  const { addScrollLock, removeScrollLock } = useScrollLock();

  useEffect(() => {
    if (isActive) {
      addScrollLock("header");
    } else {
      removeScrollLock("header");
    }

    return () => removeScrollLock("header");
  }, [isActive, addScrollLock, removeScrollLock]);

  return (
    <header
      className={classNames(styles.root, {
        [styles.inverse]: isActive || activeSection === "lobby",
        [styles.hidden]: scrollDirection === 1,
      })}
    >
      <Logo className={classNames(styles.logo)} />
      <Hamburger isActive={isActive} setIsActive={setIsActive} />
      <div
        className={classNames(styles.menu, { [styles.menuActive]: isActive })}
      >
        <ol className={styles.menuList}>
          <li className={styles.menuItem}>
            <a
              href="#intro"
              onClick={(e) => {
                e.preventDefault();
                gsap.to(window, {
                  scrollTo: "#intro",
                  duration: 0,
                });
                setActiveSection("intro");
                setIsActive(false);
              }}
            >
              Welcome to 2023
            </a>
          </li>
          <li className={styles.menuItem}>
            <a
              href="#skyscraper"
              onClick={(e) => {
                e.preventDefault();
                gsap.to(window, {
                  scrollTo: "#skyscraper",
                  duration: 0,
                });
                setActiveSection("skyscraper");
                setIsActive(false);
              }}
            >
              The Autonomous Digital Enterprise
            </a>
          </li>
          <li className={styles.menuItem}>
            <a
              href="#lobby"
              onClick={(e) => {
                e.preventDefault();
                gsap.to(window, {
                  scrollTo: "#lobby",
                  duration: 0,
                });
                // set active section to skyscraper to reset lobby entry transition
                setActiveSection("skyscraper");
                setIsActive(false);
              }}
            >
              BMC{" "}
              <span className="ops-title">
                <span>ConnectedDigital</span>Ops
              </span>{" "}
              Experience
            </a>
            <ul className={styles.subMenu}>
              <li>
                <a
                  href="#ops-1"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveSection("lobby");
                    setTimeout(() => {
                      gsap.to(window, {
                        scrollTo: "#ops-1",
                        duration: 0,
                      });
                      setIsActive(false);
                    }, 1);
                  }}
                >
                  <span className="ops-title">
                    <span>ConnectedDigital</span>Ops
                  </span>{" "}
                </a>
              </li>
              <li>
                <a
                  href="#ops-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveSection("lobby");
                    setTimeout(() => {
                      gsap.to(window, {
                        scrollTo: "#ops-2",
                        duration: 0,
                      });
                      setIsActive(false);
                    }, 1);
                  }}
                >
                  <span className="ops-title">
                    <span>Service</span>Ops
                  </span>{" "}
                </a>
              </li>
              <li>
                <a
                  href="#ops-3"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveSection("lobby");
                    setTimeout(() => {
                      gsap.to(window, {
                        scrollTo: "#ops-3",
                        duration: 0,
                      });
                      setIsActive(false);
                    }, 1);
                  }}
                >
                  <span className="ops-title">
                    <span>AI</span>Ops
                  </span>{" "}
                </a>
              </li>
              <li>
                <a
                  href="#ops-4"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveSection("lobby");
                    setTimeout(() => {
                      gsap.to(window, {
                        scrollTo: "#ops-4",
                        duration: 0,
                      });
                      setIsActive(false);
                    }, 1);
                  }}
                >
                  <span className="ops-title">
                    <span>Dev</span>Ops
                  </span>{" "}
                </a>
              </li>
              <li>
                <a
                  href="#ops-5"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveSection("lobby");
                    setTimeout(() => {
                      gsap.to(window, {
                        scrollTo: "#ops-5",
                        duration: 0,
                      });
                      setIsActive(false);
                    }, 1);
                  }}
                >
                  <span className="ops-title">
                    <span>Data</span>Ops
                  </span>{" "}
                </a>
              </li>
              <li>
                <a
                  href="#ops-6"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveSection("lobby");
                    setTimeout(() => {
                      gsap.to(window, {
                        scrollTo: "#ops-6",
                        duration: 0,
                      });
                      setIsActive(false);
                    }, 1);
                  }}
                >
                  <span className="ops-title">
                    <span>Autonomous</span>Ops
                  </span>{" "}
                </a>
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </header>
  );
};
