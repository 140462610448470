export const IconUnity = ({ className }) => (
  <svg
    width="52"
    height="53"
    viewBox="0 0 52 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M25.6582 12.2261C28.7582 12.2261 31.2713 9.71305 31.2713 6.61305C31.2713 3.51305 28.7582 1 25.6582 1C22.5582 1 20.0452 3.51305 20.0452 6.61305C20.0452 9.71305 22.5582 12.2261 25.6582 12.2261Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5764 21.3214C16.5764 16.3013 20.6448 12.2329 25.6649 12.2329C30.6849 12.2329 34.7533 16.3013 34.7533 21.3214H16.5833H16.5764Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.9119 42.2205C45.0119 42.2205 47.5249 39.7074 47.5249 36.6074C47.5249 33.5074 45.0119 30.9944 41.9119 30.9944C38.8119 30.9944 36.2988 33.5074 36.2988 36.6074C36.2988 39.7074 38.8119 42.2205 41.9119 42.2205Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.823 51.3087C32.823 46.2886 36.8914 42.2202 41.9114 42.2202C46.9315 42.2202 50.9999 46.2886 50.9999 51.3087H32.8299H32.823Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0881 42.2205C13.1881 42.2205 15.7012 39.7074 15.7012 36.6074C15.7012 33.5074 13.1881 30.9944 10.0881 30.9944C6.98814 30.9944 4.4751 33.5074 4.4751 36.6074C4.4751 39.7074 6.98814 42.2205 10.0881 42.2205Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 51.3087C1 46.2886 5.06842 42.2202 10.0884 42.2202C15.1085 42.2202 19.1769 46.2886 19.1769 51.3087H1Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.8979 31.9392L19.478 35.9801"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.8979 31.9392L32.4902 36.1662"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.8979 31.9389V25.8501"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
