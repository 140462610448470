export const Play = ({ className }) => (
  <svg
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    className={className}
  >
    <path
      d="M1.51309 1.94873L13.601 9.00001L1.51309 16.0513V1.94873Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
