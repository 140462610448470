import React from "react";
import cx from "classnames";
import { Tooltip } from "react-tooltip";
import { Icon } from "../Icon";
import styles from "./IconBox.module.scss";
import "react-tooltip/dist/react-tooltip.css";

export const IconBox = ({ className, index, icon, title, bodyText }) => {
  return (
    <div className={cx(className, styles.root)}>
      {icon && <Icon icon={icon} className={styles.icon} />}
      {title && <h3 className={styles.title}>{title}</h3>}
      {bodyText && (
        <div
          className={styles.bodyText}
          dangerouslySetInnerHTML={{ __html: bodyText }}
        ></div>
      )}
      <Tooltip
        id={`ops-tooltip-${index + 1}`}
        className="ops-tooltip"
        place="bottom"
      />
    </div>
  );
};
