import React from "react";
import cx from "classnames";
import styles from "./Headshot.module.scss";

export const Headshot = ({ className, image, index, position, list }) => {
  return (
    <div className={cx(className, styles.root)}>
      {image && (
        <div className={styles.imageWrapper}>
          <img
            src={require(`../../images/solutions/${image}`)}
            alt={position}
          />
        </div>
      )}
      {index && <p className={styles.eyebrow}>{index}</p>}
      {position && <h3 className={styles.position}>{position}</h3>}
      {list && (
        <ul className={styles.list}>
          {list.map((item, index) => (
            <li key={index} className={styles.listItem}>
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
