import { ArrowDown } from "../Icons/ArrowDown";
import { ArrowRight } from "../Icons/ArrowRight";
import { ArrowPopup } from "../Icons/ArrowPopup";
import { IconAtom } from "../Icons/IconAtom";
import { IconAdoption } from "../Icons/IconAdoption";
import { IconAutomation } from "../Icons/IconAutomation";
import { IconBriefcase } from "../Icons/IconBriefcase";
import { IconBulb } from "../Icons/IconBulb";
import { IconCharts } from "../Icons/IconCharts";
import { IconCircleCheckmark } from "../Icons/IconCircleCheckmark";
import { IconClose } from "../Icons/IconClose";
import { IconCloud } from "../Icons/IconCloud";
import { IconCostChange } from "../Icons/IconCostChange";
import { IconDownload } from "../Icons/IconDownload";
import { IconExclamation } from "../Icons/IconExclamation";
import { IconGroup } from "../Icons/IconGroup";
import { IconMoney } from "../Icons/IconMoney";
import { IconOperational } from "../Icons/IconOperational";
import { IconPerformance } from "../Icons/IconPerformance";
import { IconPlatform } from "../Icons/IconPlatform";
import { IconReliability } from "../Icons/IconReliability";
import { IconTransform } from "../Icons/IconTransform";
import { IconSupport } from "../Icons/IconSupport";
import { IconTarget } from "../Icons/IconTarget";
import { IconTruck } from "../Icons/IconTruck";
import { IconTools } from "../Icons/IconTools";
import { IconUnity } from "../Icons/IconUnity";
import { IconVisibility } from "../Icons/IconVisibility";
import { IconWorkflows } from "../Icons/IconWorkflows";
import { IconAiOps } from "../Icons/IconAiOps";
import { IconAutonomousOps } from "../Icons/IconAutonomousOps";
import { IconDevOps } from "../Icons/IconDevOps";
import { IconDataOps } from "../Icons/IconDataOps";
import { IconServiceOps } from "../Icons/IconServiceOps";
import { IconDeliver } from "../Icons/IconDeliver";
import { IconReframe } from "../Icons/IconReframe";
import { IconAutomate } from "../Icons/IconAutomate";

export const IconList = {
  arrowDown: ArrowDown,
  arrowPopup: ArrowPopup,
  arrowRight: ArrowRight,
  atom: IconAtom,
  adoption: IconAdoption,
  automation: IconAutomation,
  briefcase: IconBriefcase,
  bulb: IconBulb,
  circleCheckmark: IconCircleCheckmark,
  charts: IconCharts,
  close: IconClose,
  cloud: IconCloud,
  costChange: IconCostChange,
  download: IconDownload,
  exclamation: IconExclamation,
  group: IconGroup,
  money: IconMoney,
  operational: IconOperational,
  performance: IconPerformance,
  platform: IconPlatform,
  reliability: IconReliability,
  support: IconSupport,
  target: IconTarget,
  transform: IconTransform,
  truck: IconTruck,
  tools: IconTools,
  unity: IconUnity,
  visibility: IconVisibility,
  workflows: IconWorkflows,
  aiOps: IconAiOps,
  autonomousOps: IconAutonomousOps,
  dataOps: IconDataOps,
  devOps: IconDevOps,
  serviceOps: IconServiceOps,
  deliver: IconDeliver,
  reframe: IconReframe,
  automate: IconAutomate,
};
