export const IconReliability = ({ className }) => (
  <svg
    className={className}
    width="64"
    height="49"
    viewBox="0 0 64 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.673 8.93869C38.3245 8.93869 43.7166 7.16156 43.7166 4.96935C43.7166 2.77714 38.3245 1 31.673 1C25.0215 1 19.6294 2.77714 19.6294 4.96935C19.6294 7.16156 25.0215 8.93869 31.673 8.93869Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.7166 12.9185C43.7166 15.1161 38.324 16.8878 31.673 16.8878C25.0219 16.8878 19.6294 15.1064 19.6294 12.9185"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.7166 20.8657C43.7166 23.0634 38.324 24.8351 31.673 24.8351C25.0219 24.8351 19.6294 23.0537 19.6294 20.8657"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6294 4.96924V28.8144C19.6294 31.012 25.0219 32.7837 31.673 32.7837C38.324 32.7837 43.7166 31.0023 43.7166 28.8144V4.96924"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.8128 45.1109C34.8128 46.8421 33.4043 48.2505 31.6731 48.2505C29.9419 48.2505 28.5334 46.8421 28.5334 45.1109C28.5334 43.3797 29.9419 41.9712 31.6731 41.9712C33.4043 41.9712 34.8128 43.3797 34.8128 45.1109Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.6729 41.897L31.6729 33"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5576 45.6665H28.339"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.0071 45.6665H52.7885"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.2173 25.449L3.8895 25.449L3.8895 32.3047"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="3.88968"
      cy="3.88968"
      r="3.13968"
      transform="matrix(0 -1 -1 0 7.77954 39.897)"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.8979 25.449L59.2257 25.449V32.3047"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="59.2254"
      cy="36.0073"
      r="3.13968"
      transform="rotate(-90 59.2254 36.0073)"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
