/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import ReactHowler from "react-howler";
import { PlayAudio } from "../../../PlayAudio/PlayAudio";
import styles from "./Introduction.module.scss";

export const Introduction = ({
  productName,
  audioFile,
  audioHeading,
  audioImage,
  audioText,
  heading,
  text,
  isPlaying,
  setIsPlaying,
}) => {
  const [totalDuration, setTotalDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const howlerRef = useRef(null);

  const handleSliderChange = (newPercentage) => {
    howlerRef.current.howler.seek((newPercentage / 100) * totalDuration);
  };

  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        if (howlerRef.current) {
          setCurrentTime(howlerRef.current.howler.seek());
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isPlaying]);

  const percentage = Math.round((currentTime / totalDuration) * 100);

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <div className={styles.head}>
            <span className={styles.headTitle}>{`// Introduction`}</span>
            <span
              className={styles.badge}
              dangerouslySetInnerHTML={{ __html: productName }}
            />
          </div>
          <div className={styles.content}>
            {heading && (
              <h2
                className={styles.heading}
                dangerouslySetInnerHTML={{ __html: heading }}
              />
            )}
            {text && (
              <p className={styles.text}>
                {text.map((para, i) => (
                  <span key={i}>
                    {para} <br />
                    <br />
                  </span>
                ))}
              </p>
            )}
          </div>
        </div>
        <div className={styles.aside}>
          <div className={styles.head}>
            <span className={styles.headTitle}>Listen</span>
          </div>
          <div className={styles.audioWrapper}>
            {audioImage && (
              <div className={styles.headshotWrapper}>
                <img
                  src={`/headshots/${audioImage}`}
                  alt={""}
                  className={styles.headshot}
                />
              </div>
            )}
            <div className={styles.content}>
              {audioHeading && (
                <h2 className={styles.audioHeading}>{audioHeading}</h2>
              )}
              {audioText && <p className={styles.audioText}>{audioText}</p>}

              <PlayAudio
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                percentage={percentage}
                currentTime={Math.round(currentTime)}
                totalDuration={Math.round(totalDuration)}
                onSliderChange={handleSliderChange}
              />

              <ReactHowler
                ref={howlerRef}
                src={audioFile}
                playing={isPlaying}
                onLoad={() => {
                  setTotalDuration(howlerRef.current.howler.duration());
                }}
                onEnd={() => {
                  setIsPlaying(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
