export const IconCloud = ({ className }) => (
  <svg
    className={className}
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6881 29.4048V37.197H5.51929"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="2.92208"
      cy="37.1965"
      r="2.17208"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.3119 29.4048V37.197H44.4807"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="2.92208"
      cy="2.92208"
      r="2.17208"
      transform="matrix(-1 0 0 1 50 34.2744)"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="32.7922"
      cy="47.5866"
      r="2.17208"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.792 29.4048L32.792 44.6645"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="17.2077"
      cy="47.5866"
      r="2.17208"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.208 29.4048L17.208 44.6645"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="25.0002"
      cy="42.7155"
      r="2.17208"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25 29.4048L25 39.7944"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.9315 28.729H37.2055C39.8031 28.729 42.2944 27.7022 44.1312 25.8744C45.9681 24.0465 47 21.5675 47 18.9826C47 16.3977 45.9681 13.9187 44.1312 12.0909C42.2944 10.2631 39.8031 9.23623 37.2055 9.23623H36.8287C35.6937 6.73083 33.7752 4.65761 31.3594 3.32575C28.9436 1.99388 26.1599 1.47471 23.4234 1.84565C20.6869 2.2166 18.1441 3.45779 16.1744 5.38413C14.2046 7.31048 12.9133 9.81878 12.4931 12.535C11.3617 12.2242 10.1769 12.1561 9.0171 12.3351C7.85729 12.5142 6.74893 12.9363 5.76538 13.5736C4.78182 14.2109 3.94552 15.0488 3.31182 16.0319C2.67812 17.0149 2.26148 18.1207 2.08948 19.276C1.91748 20.4313 1.99404 21.6098 2.31409 22.7335C2.63414 23.8571 3.19039 24.9003 3.94601 25.794C4.70163 26.6877 5.63939 27.4115 6.69721 27.9174C7.75503 28.4234 8.9088 28.7 10.0821 28.729H16.863"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
