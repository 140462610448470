export const IconAutonomousOps = ({ className }) => (
  <svg
    className={className}
    width="42"
    height="41"
    viewBox="0 0 42 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.1225 9.90763C34.1225 9.90763 34.1 9.90763 34.0887 9.90763C34.1056 9.69394 34.1225 9.47462 34.1225 9.25531C34.1225 5.27949 30.8946 2.0516 26.9187 2.0516C25.3779 2.0516 23.9495 2.54084 22.7798 3.36187C21.0984 1.89414 18.8996 1 16.4928 1C11.6228 1 7.60199 4.64966 7.01153 9.36215C3.59806 9.93013 1 12.8881 1 16.459C1 20.4348 4.22789 23.6627 8.20371 23.6627H34.1225C37.9183 23.6627 41 20.5811 41 16.7852C41 12.9893 37.9183 9.90763 34.1225 9.90763Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6398 29.7559L21 27.3973M18.6398 29.7559L21 32.1146M18.6398 29.7559H12.3458M21 27.3973L23.3603 29.7559M21 27.3973V23.3M23.3603 29.7559L21 32.1146M23.3603 29.7559H29.6543M21 32.1146V35.0426M23.3603 37.9123H34.3748V32.5077M2.90479 27.3973H12.3458V32.1146H2.90479V27.3973ZM29.6543 27.3973H39.0953V32.1146H29.6543V27.3973ZM23.3603 37.9246C23.3603 39.2272 22.3036 40.2832 21 40.2832C19.6965 40.2832 18.6398 39.2272 18.6398 37.9246C18.6398 36.622 19.6965 35.566 21 35.566C22.3036 35.566 23.3603 36.622 23.3603 37.9246Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.8571 13.4484L27.4286 16.0198L30 13.4484M17.1429 13.4484L14.5714 10.877L12 13.4484"
      stroke="#30294D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.0539 19.1332C22.8444 19.7951 21.4517 20.0434 20.088 19.8403C18.7243 19.6372 17.4644 18.9938 16.5003 18.0082C15.6487 17.1442 15.0567 16.0586 14.7915 14.8748C14.5263 13.691 14.5987 12.4565 15.0003 11.3118M17.9468 7.77605C19.1562 7.11418 20.5489 6.86587 21.9126 7.06897C23.2763 7.27208 24.5362 7.91545 25.5003 8.90105C26.3519 9.76505 26.944 10.8507 27.2092 12.0345C27.4743 13.2183 27.402 14.4528 27.0003 15.5975"
      stroke="#30294D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
